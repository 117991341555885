import React from 'react'
import useLanguagesQuery from 'services/languages';

interface IProps {
    item:any
}

const NavTabsItem: React.FC<IProps>= (props) =>{
    const {
        data: Languages,
    } = useLanguagesQuery.useGetAll();
    const { item } =props
    return (
              <>
                  { Languages?.objects?.find(c => c.id === item.language_id)?.long_name}
              </>
    )
}

export default NavTabsItem