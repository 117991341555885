import { AxiosResponse } from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import {
  FaqQuestions,
  FaqQuestionData,
  FaqCategories,
  FaqCategoryData,
} from "../../shared/models/FormEntity";
import { APIClient } from "../../utils/api/client";
import { IError } from "../../utils/models";
import { getBaseUrl } from "../endpoints";

import { queryToString } from "../../utils/functions";

export interface IGetFaqQuestionsConfig {}
export interface IGetFaqCategoriesConfig {}

const FaqCategoryServices = {
  getAll: (data?: any): Promise<FaqCategories> => {
    return APIClient.get(
      getBaseUrl({ path: `content/faq_categories${queryToString(data.query)}` })
    );
  },
  getSingle: (
    data: string | string[] | undefined
  ): Promise<FaqCategoryData[]> => {
    return APIClient.get(
      getBaseUrl({ path: `content/faq_categories/${data}` })
    );
  },
  create: (data?: any): Promise<AxiosResponse<FaqCategories>> => {
    return APIClient.post(getBaseUrl({ path: "content/faq_categories" }), data);
  },
  update: (data?: any): Promise<AxiosResponse<FaqCategoryData[]>> => {
    return APIClient.put(getBaseUrl({ path: "content/faq_categories" }), data);
  },
  delete: (data?: any): Promise<AxiosResponse<{}>> => {
    return APIClient.delete(getBaseUrl({ path: "content/faq_categories" }), {
      data: data,
    });
  },
};
const FaqQuestionServices = {
  getAll: (data?: any): Promise<FaqQuestions> => {
    return APIClient.get(
      getBaseUrl({ path: `content/faq_questions${queryToString(data.query)}` })
    );
  },
  getSingle: (
    data: string | string[] | undefined
  ): Promise<FaqQuestionData[]> => {
    return APIClient.get(
      getBaseUrl({ path: `content/faq_questions/${data}` })
    );
  },
  create: (data?: any,category_id?:number): Promise<AxiosResponse<FaqQuestions>> => {
    return APIClient.post(getBaseUrl({ path: `content/faq_categories/${category_id}/questions` }), data);
  },
  update: (data?: any): Promise<AxiosResponse<FaqQuestionData[]>> => {
    return APIClient.put(getBaseUrl({ path: "content/faq_questions" }), data);
  },
  delete: (data?: any): Promise<AxiosResponse<{}>> => {
    return APIClient.delete(getBaseUrl({ path: "content/faq_questions" }), {
      data: data,
    });
  },
};

export const useFaqCategoriesQuery = {
  useGetAll: (
    data?: any,
    meta?: IGetFaqCategoriesConfig
  ): UseQueryResult<FaqCategories, IError> => {
    return useQuery<FaqCategories, IError>(
      ["get-faq_categories", data],
      async (): Promise<FaqCategories> => {
        const res = await FaqCategoryServices.getAll(data);
        return res;
      }
    );
  },
  useGetSingle: (
    identifier: string | string[] | undefined,
    meta?: IGetFaqCategoriesConfig
  ): UseQueryResult<FaqCategoryData[]> => {
    return useQuery<FaqCategoryData[]>(
      ["get-faq_category", identifier],
      async (): Promise<FaqCategoryData[]> => {
        const res = await FaqCategoryServices.getSingle(identifier);
        return res;
      },
      {
        enabled: true,
      }
    );
  },
  useCreate: (
    onSuccess: () => void
  ): UseMutationResult<FaqCategories, IError> => {
    return useMutation<FaqCategories, IError, unknown>(
      async (data: any): Promise<FaqCategories> => {
        const res = await FaqCategoryServices.create(data);
        return res?.data;
      },
      {
        onSuccess: onSuccess,
      }
    );
  },
  useUpdate: (
    onSuccess: () => void
  ): UseMutationResult<FaqCategoryData[], IError> => {
    return useMutation<FaqCategoryData[], IError, unknown>(
      async (data: any): Promise<FaqCategoryData[]> => {
        const res = await FaqCategoryServices.update(data);
        return res?.data;
      },
      {
        onSuccess: onSuccess,
      }
    );
  },
  useDelete: (
    onSuccess: (data: any) => void
  ): UseMutationResult<{}, IError> => {
    return useMutation<{}, IError, unknown>(
      async (data: any): Promise<{}> => {
        const res = await FaqCategoryServices.delete(data);
        return res;
      },
      {
        onSuccess: onSuccess,
      }
    );
  },
};
export const useFaqQuestionQuery = {
      useGetAll: (
        data?: any,
        meta?: IGetFaqQuestionsConfig
      ): UseQueryResult<FaqQuestions, IError> => {
        return useQuery<FaqQuestions, IError>(
          ["get-questions", data],
          async (): Promise<FaqQuestions> => {
            const res = await FaqQuestionServices.getAll(data);
            return res;
          }
        );
      },
      useGetSingle: (
        identifier: string | string[] | undefined,
        meta?: IGetFaqQuestionsConfig
      ): UseQueryResult<FaqQuestionData[]> => {
        return useQuery<FaqQuestionData[]>(
          ["get-question", identifier],
          async (): Promise<FaqQuestionData[]> => {
            const res = await FaqQuestionServices.getSingle(identifier);
            return res;
          },
          {
            cacheTime:0
          }
        );
      },
      useCreate: (
        onSuccess: () => void,
      ): UseMutationResult<FaqQuestions, IError> => {
        return useMutation<FaqQuestions, IError, unknown>(
          async (data: any): Promise<FaqQuestions> => {
            const category_id = data.category_id
            const res = await FaqQuestionServices.create(data,category_id);
            return res?.data;
          },
          {
            onSuccess: onSuccess,
          }
        );
      },
      useUpdate: (
        onSuccess: () => void
      ): UseMutationResult<FaqQuestionData[], IError> => {
        return useMutation<FaqQuestionData[], IError, unknown>(
          async (data: any): Promise<FaqQuestionData[]> => {
            const res = await FaqQuestionServices.update(data);
            return res?.data;
          },
          {
            onSuccess: onSuccess,
          }
        );
      },
      useDelete: (
        onSuccess: (data: any) => void
      ): UseMutationResult<{}, IError> => {
        return useMutation<{}, IError, unknown>(
          async (data: any): Promise<{}> => {
            const res = await FaqQuestionServices.delete(data);
            return res;
          },
          {
            onSuccess: onSuccess,
          }
        );
      },

};

