import {
  useQuery,
  UseQueryResult,
} from "react-query";
import { Countries, CountriesData } from "../../shared/models/FormEntity";
import { APIClient } from "../../utils/api/client";
import { IError } from "../../utils/models";
import { API_URL, ACE_URL } from "services/endpoints";

export interface IGetCountriesConfig {
}
export interface IGetCountriesQueryParams{
}

const Countrieservices = {
  getAll: (): Promise<Countries> => {
    return APIClient.get(API_URL + "/content/countries/ace?with_continent=0&");
  },
  getSingle: (data: string | string[] | undefined): Promise<CountriesData[]> => {
    return APIClient.get(ACE_URL + `/countries/alpha3-code/${data}`);
  }
};

export const useCountryQuery = {
  useGetAll: (
  ): UseQueryResult<Countries, IError> => {
    return useQuery<Countries, IError>(
      ["get-countries"],
      async (): Promise<Countries> => {
        const res = await Countrieservices.getAll();
        return res;
      },
    );
  },
  useGetSingle: (
    identifier: string | string[] | undefined,
    meta?: IGetCountriesConfig
  ): UseQueryResult<CountriesData[]> => {
    return useQuery<CountriesData[]>(
      ["get-country", identifier],
      async (): Promise<CountriesData[]> => {
        const res = await Countrieservices.getSingle(identifier);
        return res;
      }
    );
  },
};

export default useCountryQuery;
