import React, { useState } from 'react'
import {
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Row,
} from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import { useFieldArray, useForm,Controller } from "react-hook-form";
import {
    useNavigate,
    useParams
  } from "react-router-dom";
import useTranslationQuery from 'services/template_variable';
import NavTabsItem from 'shared/components/Tabs';
interface IProps {

}

const EditTranslations: React.FC<IProps>= () =>{
  const params = useParams();
  let navigate = useNavigate();

  const { data: Translations,isLoading } = useTranslationQuery.useGetSingle(params.id)

  const [activeTab, setActiveTab] = useState('1');
  const { control, handleSubmit, formState: { errors } } = useForm();
  const { fields, append } = useFieldArray({
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "template_variables", // unique name for your Field Array
  });
  React.useEffect(() => {
    if (Translations) {
    Translations.forEach((tm) => {
        append(tm);
      });
    }
  }, [Translations]);
  const onSuccessSubmit = () => {
    toast('Template variable updated', {
        duration: 2000,
        position: 'top-center',
        icon: '👍🏻',
      });
    setTimeout(() => {
        navigate(-1)
    }, 1000);
}
  
const {
  mutate: mutateTranslationUpdate,
} = useTranslationQuery.useUpdate(onSuccessSubmit);
const onSubmit = (data: any) => {
  const { template_variables } =data
  template_variables.map(
   (el:any)=>
    {
      delete el['unique_identifier']
    }
  )
  mutateTranslationUpdate(template_variables)
};

  return (
    <>
    {isLoading ? (
        <div>Loading...</div>
        ) : (
        <Card className="mt-3">
        <Toaster />
        <CardBody>
            <h4 className="header-title">Template variable</h4>
            <Form onSubmit={handleSubmit(onSubmit)}>
            <Nav tabs>
                {fields?.map((i: any, index) => (
                <>
                    <NavItem>
                    <NavLink
                        className={activeTab === `${index + 1}` ? "active" : ""}
                        onClick={function noRefCheck() {
                        setActiveTab(`${index + 1}`);
                        }}
                    >
                        <NavTabsItem item={i}/>
                    </NavLink>
                    </NavItem>
                </>
                ))}
            </Nav>
            <TabContent activeTab={activeTab}>
                {fields?.map((i: any, index) => (
                <TabPane tabId={(index + 1).toString()} key={i.id}>
                    <Row>
                    <Col sm="12">
                        <div>
                        <FormGroup row>
                            <Label for="key" sm={3}>
                            Key
                            </Label>
                            <Col sm={9}>
                             <Controller
                                            name={`template_variables.${index}.key`}
                                            control={control}
                                            render={({ field }) => (
                                            <Input {...field} type="text" id="key" />
                                            )}
                                        />
                            </Col>
                            {errors.value && <span>This field is required</span>}
                        </FormGroup>
                        </div>
                    </Col>

                    <Col sm="12">
                        <div>
                        <FormGroup row>
                            <Label for="value" sm={3}>
                            Value
                            </Label>
                            <Col sm={9}>
                             <Controller
                                            name={`template_variables.${index}.value`}
                                            control={control}
                                            render={({ field }) => (
                                            <Input {...field} type="text" id="value" />
                                            )}
                                        />
                            </Col>
                            {errors.value && <span>This field is required</span>}
                        </FormGroup>
                        </div>
                    </Col>
                    </Row>
                </TabPane>
                ))}
            </TabContent>
            <Button color="primary" type="submit">
                Submit
            </Button>
            </Form>
        </CardBody>
        </Card>
    )}
    </>
  );
}

export default EditTranslations