import * as React from 'react';
import {
    Card,
    CardBody,
    Button,
} from 'reactstrap';

import { Link } from 'react-router-dom';
import RemotePagination from 'shared/components/Pagination';
import * as FeatherIcon from 'react-feather';
import useDomainsQuery from 'services/domains';
import moment from 'moment';
import toast, { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
interface IProps {

}

const Domains: React.FC<IProps>= () =>  {
    const [page,setPage] = React.useState(0)
    const onSuccessSubmit = (data:any) => {
        toast(data.message, {
            duration: 2000,
            position: 'top-center',
            icon: '👍🏻',
          });
        setTimeout(() => {
            // navigate(-1)
        }, 1000);
    }
    const onDeleteSubmit = () => {
        toast('Domain has been successfully deleted.', {
            duration: 2000,
            position: 'top-center',
            icon: '👍🏻',
          });
    }
    const {
        mutate: mutateDomainsGenerate,
    } = useDomainsQuery.useGenerate((data) => onSuccessSubmit(data));
    const {
        mutate: mutateDomainDelete,
    } = useDomainsQuery.useDelete(() => onDeleteSubmit());
    function GenerateDomain(cell:any, row:any) {
        return (
           <Button color="outline-success"> <FeatherIcon.Terminal className="icon-xs icon-dual"></FeatherIcon.Terminal></Button>
        );
    }
    function DeleteDomain(cell:any, row:any) {
        return (
           <Button color="outline-danger"> <FeatherIcon.Trash className="icon-xs icon-dual"></FeatherIcon.Trash></Button>
        );
    }
    function CreatedAt(row:any) {
        return (
            <span>{moment(row.created_at).format("D-MMM-YYYY, h:mm a")}</span>
        );
    }
    function UpdatedAt(row:any) {
        return (
            <span>{moment(row.updated_at).format("D-MMM-YYYY, h:mm a")}</span>
        );
    }
    const columns = [
        {
            dataField: 'id',
            text: 'ID',
        },
        {
            dataField: 'url',
            text: 'Url',
        },
        {
            dataField: 'created_at',
            text: 'Created at',
            formatter: CreatedAt
        },
        {
            dataField: 'updated_at',
            text: 'Updated at',
            formatter: UpdatedAt
        },
        {
            dataField: 'generate',
            text: 'Generate',
            formatter: GenerateDomain,
            events: {
                onClick: (e:any, column:any, columnIndex:any, row:any) => {
                    mutateDomainsGenerate(row.id)
                }
            }
        },
        {
            dataField: 'delete',
            text: 'Delete',
            formatter: DeleteDomain,
            events: {
                onClick: (e:any, column:any, columnIndex:any, row:any) => {
                    if (window.confirm("Do you really want to delete this domain 😳?") === true) {
                        mutateDomainDelete(
                            {
                                'id':row.id
                            }
                        )
                      } else {
                       
                      }
                }
            }
        }
    ];
    const { language } = useSelector((state:any) => state.LangReducer);
    const { search_text } = useSelector((state:any) => state.SearchReducer);
    const {
        data: Domains,
    } = useDomainsQuery.useGetAll({
            'language_id':language,
            'limit':10,
            'prev':page * 10,
            ...(search_text ? {"search_key": search_text} : "")
        }
    );
    const  handleTableChange = (type:any,{page}:any) => {
        setPage(page-1)
    }


    return (
        <>
            <Card className='mt-3'>
                <Toaster />
                <CardBody>
                    <Link to="/wadmin/domains/create">
                        <Button color="primary" type="submit">
                            Create Domains
                        </Button>
                    </Link>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <RemotePagination
                        data={Domains?.objects || []}
                        page={page+1}
                        sizePerPage={Domains?.meta.limit}
                        totalSize={ (Domains?.meta.limit as number) * (Domains?.meta.max_page as number)} keyField={''} columns={columns}
                        onTableChange={handleTableChange}               // onTableChange={ this.handleTableChange }
                    />

                </CardBody>
            </Card>
        </>
   
      
    )
} 

export default Domains;
