import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { logoutUser } from '../../../redux/auth/actions';

interface IProps{
}
const Logout: React.FC<IProps>= (props) =>  {
    const dispatch = useDispatch()
    let history : NavigateFunction = useNavigate();
  
    useEffect(() => {
        dispatch(logoutUser(history))
    }, [dispatch, history])
    return (<React.Fragment></React.Fragment>)
    
}
export default Logout