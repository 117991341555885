import * as React from 'react';
import { useFaqCategoriesQuery } from 'services/faq';
import {
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
} from 'reactstrap';
import { useForm } from "react-hook-form";
import SelectLanguage from 'shared/components/Inputs/SelectLanguage';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

interface IProps {

}
const CreateFaqCategory: React.FC<IProps>= (props) =>  {
    let navigate = useNavigate();
    const onSuccessSubmit = () => {
        toast('Faq Category created', {
            duration: 2000,
            position: 'top-center',
            style: {
                // background:'#d9f6ec'
            },
            className: '',
            icon: '👍🏻',
            iconTheme: {
              primary: '#000',
              secondary: '#f2f2f2',
            },
            ariaProps: {
              role: 'status',
              'aria-live': 'polite',
            },
          });
        setTimeout(() => {
            navigate(-1)
        }, 1000);
    }
    const {
        mutate: mutateFaqCategoryCreate,
        isLoading,
    } = useFaqCategoriesQuery.useCreate(onSuccessSubmit);
     
    const  onSubmit = (data:{}) => {
        mutateFaqCategoryCreate(data)
    };
  
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const {ref:titleRef,...title} = register('title', { required: true })
    const {ref:priorityRef,...priority} = register('priority', { required: true })


    return (
   
    <Card className='mt-3'>
            <Toaster />
            <CardBody>
                <h4 className="header-title">Seo texts</h4>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup row>
                        <Label for="title" sm={3}>
                            Title
                        </Label>
                        <Col sm={9}>
                            <Input id="title"  type="text"  {...title} innerRef={titleRef}   placeholder="Enter title" />
                              {errors.title && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="priority" sm={3}>
                            Priority
                        </Label>
                        <Col sm={9}>
                            <Input id="priority"  type="number"  {...priority} innerRef={priorityRef}   placeholder="Enter priority" />
                              {errors.title && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="language_id" sm={3}>
                            Select language
                        </Label>
                        <Col sm={9}>
                            <SelectLanguage {...register('language_id', { required: true })} onChange={(value:any)=>{
                                setValue('language_id',value.value)
                            }}/>
                            {errors.language_id && <span>This field is required</span>}
                        </Col>
                    </FormGroup> 

                    <Button color="primary" type="submit">
                        {
                            isLoading ? 'Loading...' : 'Create'
                        }
                    </Button>
                </Form>
            </CardBody>
        </Card>
      
    )
} 

export default CreateFaqCategory;
