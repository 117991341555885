import { AxiosResponse } from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { Snippets, SnippetsData } from "../../shared/models/FormEntity";
import { APIClient } from "../../utils/api/client";
import { IError } from "../../utils/models";
import { getBaseUrl } from "../endpoints";

import { queryToString } from "../../utils/functions";

export interface IGetSnippetsConfig {
}


const SnippetServices = {
  getAll: (data?: any): Promise<Snippets> => {
    return APIClient.get(getBaseUrl({path:`snippets${queryToString(data)}`}));
  },
  getSingle: (data: string | string[] | undefined): Promise<SnippetsData[]> => {
    return APIClient.get(getBaseUrl({path:`snippets/${data}`}));
  },
  create: (data?: any): Promise<AxiosResponse<Snippets>> => {
    return APIClient.post( getBaseUrl({path:'snippets'}), data);
  },
  update: (data?: any): Promise<AxiosResponse<SnippetsData[]>> => {
    return APIClient.patch( getBaseUrl({path:`snippets/${data[0].snippet_id}`}), data[0]);
  },
  delete: (data?: any): Promise<AxiosResponse<{}>> => {
    return APIClient.delete(getBaseUrl({path:`snippets/${data}`}));
  },
};

export const useSnippetQuery = {
  useGetAll: (
    data?: any,
    meta?: IGetSnippetsConfig
  ): UseQueryResult<Snippets, IError> => {
    return useQuery<Snippets, IError>(
      ["get-snippets", data],
      async (): Promise<Snippets> => {
        const res = await SnippetServices.getAll(data);
        return res;
      }
    );
  },
  useGetSingle: (
      identifier: string | string[] | undefined,
      meta?: IGetSnippetsConfig
    ): UseQueryResult<SnippetsData[]> => {
      return useQuery<SnippetsData[]>(
        ["get-snippet", identifier],
        async (): Promise<SnippetsData[]> => {
          const res = await SnippetServices.getSingle(identifier);
          return res;
        },
        {
            cacheTime:0
        }
      );
    },
  useCreate: (onSuccess:() => void): UseMutationResult<Snippets, IError> => {
    return useMutation<Snippets, IError, unknown>(
      async (data:any): Promise<Snippets> => {
        const res = await SnippetServices.create(data);
        return res?.data;
      },
      {
        onSuccess:onSuccess
      }
    );
  },
  useUpdate: (onSuccess:() => void): UseMutationResult<SnippetsData[], IError> => {
    return useMutation<SnippetsData[], IError, unknown>(
      async (data:any): Promise<SnippetsData[]> => {
        const res = await SnippetServices.update(data);
        return res?.data;
      },
      {
        onSuccess:onSuccess
      }
    );
  },
  useDelete: (onSuccess:(data:any) => void): UseMutationResult<{}, IError> => {
    return useMutation<{}, IError, unknown>(
      async (data:any): Promise<{}> => {
        const res = await SnippetServices.delete(data);
        return res;
      },
      {
        onSuccess:onSuccess
      },
      
    );
  },
};

export default useSnippetQuery;
