import * as React from "react";
import useCountryQuery from "services/countries";
import { Modify } from "../../../../utils/functions";
import Select from 'react-select';
import { StateManagerProps } from "react-select/dist/declarations/src/stateManager";
import { CountriesData } from "../../../models/FormEntity";

export interface ISelectCountryProps extends StateManagerProps {
  getValue?: any
}

const SelectCountry: React.FunctionComponent<ISelectCountryProps> = (props) => {
  const {getValue} = props;
  
  const {
    data: countries,
    isLoading,
  } = useCountryQuery.useGetAll();
  const getCountryOptions = (countries:CountriesData[] | undefined) => {
    return countries?.map(country => {
         return {
           label: country.name,
           value: country.alpha_3_code
         }
    })
}

  return <Select
        className="basic-single"
        classNamePrefix="select"
        isLoading={isLoading}
        name="color"
        value={getCountryOptions(countries?.data)?.find(c => c.value === getValue)}
        options={Modify.selectOptionsCountry(countries?.data|| [])}
        {...props}
        />
};

export default SelectCountry;
