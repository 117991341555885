import React from 'react'
import {
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
} from 'reactstrap';
import { useFieldArray, useForm,Controller } from "react-hook-form";
import {
    useNavigate,
    useParams
  } from "react-router-dom";
import { useUsersQuery } from 'services/users';
import toast, { Toaster } from 'react-hot-toast';
interface IProps {

}

const ViewUserDetail: React.FC<IProps>= () =>{
  const params = useParams();
  let navigate = useNavigate();
  const onDeleteSubmit = () => {
    toast('User has been successfully deleted.', {
        duration: 2000,
        position: 'top-center',
        icon: '👍🏻',
      });
      setTimeout(() => {
        navigate(-1)
      }, 1000);
}
  const {
    mutate: mutateStaticDelete,
} = useUsersQuery.useDelete(() => onDeleteSubmit());
  const { data: User,isLoading } = useUsersQuery.useGetSingle(params.id)

  const { control, handleSubmit } = useForm();
  const { fields, append } = useFieldArray({
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "user", // unique name for your Field Array
  });
  React.useEffect(() => {
    if (User) {
        append(User);
    }
  }, [User]);
    function onSubmit(){
        mutateStaticDelete(
            {
                'id':params.id
            }
        )
    }
  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <Card className="mt-3">
        <Toaster/>
          <CardBody>
            <h4 className="header-title">User</h4>
            <Form onSubmit={handleSubmit(onSubmit)}>
              {fields?.map((i: any, index) => (
                  <Row key={index}>
                    <Col sm="12">
                      <div>
                        <FormGroup row>
                          <Label for="email" sm={3}>
                            Email
                          </Label>
                          <Col sm={9}>
                            <Controller
                              name={`user.${index}.email`}
                              control={control}
                              render={({ field }) => (
                                <Input {...field} type="text" id="email" disabled />
                              )}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row >
                          <Label for="customer_id" sm={3}>
                            Customer id
                          </Label>
                          <Col sm={9}>
                            <Controller
                              name={`user.${index}.customer_id`}
                              control={control}
                              render={({ field }) => (
                                <Input {...field} type="text" id="customer_id" disabled/>
                              )}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="name" sm={3}>
                            Name
                          </Label>
                          <Col sm={9}>
                            <Controller
                              name={`user.${index}.name`}
                              control={control}
                              render={({ field }) => (
                                <Input {...field} type="text" id="name" disabled/>
                              )}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="surname" sm={3}>
                            Surname
                          </Label>
                          <Col sm={9}>
                            <Controller
                              name={`user.${index}.surname`}
                              control={control}
                              render={({ field }) => (
                                <Input {...field} type="text" id="surname" disabled/>
                              )}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="phone" sm={3}>
                            Phone
                          </Label>
                          <Col sm={9}>
                            <Controller
                              name={`user.${index}.phone`}
                              control={control}
                              render={({ field }) => (
                                <Input {...field} type="text" id="phone" disabled/>
                              )}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="is_admin" sm={3}>
                            Is admin
                          </Label>
                          <Col sm={9}>
                            <Controller
                              name={`user.${index}.is_admin`}
                              control={control}
                              render={({ field }) => (
                                <Input {...field} type="text" id="is_admin" disabled/>
                              )}
                            />
                          </Col>
                        </FormGroup>
                      </div>
                    </Col>
                  </Row>
              ))}
              <Button color="danger" type="submit">
                Delete
              </Button>
            </Form>
          </CardBody>
        </Card>
      )}
    </>
  );
}

export default ViewUserDetail