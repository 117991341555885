import { AxiosResponse } from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { DomainData, Domains } from "../../shared/models/FormEntity";
import { APIClient } from "../../utils/api/client";
import { IApiParams, IError } from "../../utils/models";
import Endpoints, { getBaseUrl } from "../endpoints";

// export interface IGetAdsQueryParams {
//   isCredit?: boolean;
//   isExchange?: boolean;
// }
export interface IGetDomainsConfig {
}
export interface IGetDomainsQueryParams{

}
const DomainsServices = {
  getAll: (data?: any): Promise<Domains> => {
    const params: IApiParams<null, null, null> = {
      query: { ...data },
    };
    return APIClient.get(Endpoints.domain.getAll(params));
  },
  getSingle: (data: string | string[] | undefined): Promise<DomainData[]> => {
    return APIClient.get(getBaseUrl({path:`content/domains/${data}`}));
  },
  create: (data?: any): Promise<AxiosResponse<DomainData>> => {
    const params: IApiParams<null, null, null> = { body: { ...data } };
    return APIClient.post(Endpoints.domain.getAll(params), { ...data });
  },
  generate: (data?: any): Promise<AxiosResponse<{}>> => {
    return APIClient.post(Endpoints.domain.getConfig(data));
  },
  delete: (data?: any): Promise<AxiosResponse<{}>> => {
    return APIClient.delete(Endpoints.domain.getBase(),{ data:data });
  },
};

export const useDomainsQuery = {
  useGetAll: (
    data?: IGetDomainsQueryParams,
    meta?: IGetDomainsConfig
  ): UseQueryResult<Domains, IError> => {
    return useQuery<Domains, IError>(
      ["domains", data],
      async (): Promise<Domains> => {
        const res = await DomainsServices.getAll(data);
        return res;
      }
    );
  },
  useGetSingle: (
      identifier: string | string[] | undefined,
      meta?: IGetDomainsConfig
    ): UseQueryResult<DomainData[]> => {
      return useQuery<DomainData[]>(
        ["get-domain", identifier],
        async (): Promise<DomainData[]> => {
          const res = await DomainsServices.getSingle(identifier);
          return res;
        }
      );
    },
  useCreate: (onSuccess:() => void): UseMutationResult<DomainData, IError> => {
    return useMutation<DomainData, IError, unknown>(
      async (data:any): Promise<DomainData> => {
        const res = await DomainsServices.create(data);
        return res?.data;
      },
      {
        onSuccess:onSuccess
      }
    );
  },
  useGenerate: (onSuccess:(data:any) => void): UseMutationResult<{}, IError> => {
    return useMutation<{}, IError, unknown>(
      async (data:any): Promise<{}> => {
        const res = await DomainsServices.generate(data);
        return res;
      },
      {
        onSuccess:onSuccess
      },
      
    );
  },
  useDelete: (onSuccess:(data:any) => void): UseMutationResult<{}, IError> => {
    return useMutation<{}, IError, unknown>(
      async (data:any): Promise<{}> => {
        const res = await DomainsServices.delete(data);
        return res;
      },
      {
        onSuccess:onSuccess
      },
      
    );
  },
};

export default useDomainsQuery;
