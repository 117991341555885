import { NavigateFunction } from 'react-router-dom';
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILED,
    LOGOUT_USER
} from './constants';
import ILoginBody from './models';


export const loginUser = (actions:ILoginBody,history:NavigateFunction) => ({
    type: LOGIN_USER,
    payload: actions,history
});

export const loginUserSuccess = (user:any) => ({
    type: LOGIN_USER_SUCCESS,
    payload: user,
});

export const loginUserFailed = (error:{}) => ({
    type: LOGIN_USER_FAILED,
    payload: error,
});
export const logoutUser = (history:NavigateFunction) => ({
    type: LOGOUT_USER,
    payload: { history },
});