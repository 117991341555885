import * as React from 'react';
import {
    Card,
    CardBody,
    Button,
} from 'reactstrap';

import { Link, useNavigate } from 'react-router-dom';
import RemotePagination from '../../shared/components/Pagination';
import useTranslationQuery from '../../services/template_variable';
import * as FeatherIcon from 'react-feather';
import toast, { Toaster } from 'react-hot-toast';
import moment from 'moment';
import { useSelector } from 'react-redux';

interface IProps {

}

const Translations: React.FC<IProps>= (props) =>  {
    let navigate = useNavigate();
    const [page,setPage] = React.useState(0)
    const onDeleteSubmit = () => {
        toast('Template variable has been successfully deleted.', {
            duration: 2000,
            position: 'top-center',
            icon: '👍🏻',
          });
    }
    function UpdateTranslation(cell:any, row:any) {
        return (
           <Button color="outline-info"> <FeatherIcon.Edit className="icon-xs icon-dual"></FeatherIcon.Edit></Button>
        );
    }
    function CreatedAt(row:any) {
        return (
            <span>{moment(row.created_at).format("D-MMM-YYYY, h:mm a")}</span>
        );
    }
    function UpdatedAt(row:any) {
        return (
            <span>{moment(row.updated_at).format("D-MMM-YYYY, h:mm a")}</span>
        );
    }
      function DeleteTranslation() {
        return (
           <Button color="outline-danger"> <FeatherIcon.Trash className="icon-xs icon-dual"></FeatherIcon.Trash></Button>
        );
    }
    const { language } = useSelector((state:any) => state.LangReducer);
    const { search_text } = useSelector((state:any) => state.SearchReducer);

    const {
        data: Translation,
    } = useTranslationQuery.useGetAll(
       {
            'language_id':language,
            'limit':10,
            'prev':page * 10,
            ...(search_text ? {"search_key": search_text} : "")
       }
    );
    const {
        mutate: mutateTranslationDelete,
    } = useTranslationQuery.useDelete(() => onDeleteSubmit());
    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
        },
        {
            dataField: 'key',
            text: 'Key',
        },
        {
            dataField: 'value',
            text: 'Value',
        },
        {
            dataField: 'created_at',
            formatter:CreatedAt,
            text: 'Created at',
        },
        {
            dataField: 'updated_at',
            formatter:UpdatedAt,
            text: 'Updated at',
        },
        {
            dataField: 'unique_identifier',
            text: 'Edit',
            formatter: UpdateTranslation,
            events: {
                onClick: (e:any, column:any, columnIndex:any, row:any, rowIndex:any) => {
                  navigate(`${row.unique_identifier}/edit`, { replace: false });
                }
            }
        },
        {
            dataField: 'delete',
            text: 'Delete',
            formatter: DeleteTranslation,
            events: {
                onClick: (e:any, column:any, columnIndex:any, row:any, rowIndex:any) => {
                    if (window.confirm("Do you really want to delete this template variable 😳?") === true) {
                        mutateTranslationDelete(
                            {
                                'id':row.id
                            }
                        )
                      } else {
                       
                      }
                }
            }
        }
    ];
   
   
    const  handleTableChange = (type:any,{page,sizePerPage}:any) => {
        setPage(page-1)
    }


    return (
        <>
            <Card className='mt-3'>
             <Toaster />
                <CardBody>
                    <Link to="/wadmin/template-variable/create">
                        <Button color="primary" type="submit">
                            Create Template variable
                        </Button>
                    </Link>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <RemotePagination
                        data={Translation?.objects || []}
                        page={page+1}
                        sizePerPage={Translation?.meta.limit}
                        totalSize={ (Translation?.meta.limit as number) * (Translation?.meta.max_page as number)} keyField={''} columns={columns}
                        onTableChange={handleTableChange}               // onTableChange={ this.handleTableChange }
                    />

                </CardBody>
            </Card>
        </>
   
      
    )
} 

export default Translations;
