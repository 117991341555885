import { AxiosResponse } from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { PopularDestinations, PopularDestinationsData } from "../../shared/models/FormEntity";
import { APIClient } from "../../utils/api/client";
import { IError } from "../../utils/models";
import { getBaseUrl } from "../endpoints";

import { queryToString } from "../../utils/functions";

export interface IGetPopularDestinationsConfig {
}


const PopularDestinationServices = {
  getAll: (data?: any): Promise<PopularDestinations> => {
    return APIClient.get(getBaseUrl({path:`content/popular_destination${queryToString(data)}`}))
  },
  getSingle: (data: string | string[] | undefined): Promise<PopularDestinationsData[]> => {
    return APIClient.get(getBaseUrl({path:`content/popular_destination/${data}`}));
  },
  create: (data?: any): Promise<AxiosResponse<PopularDestinations>> => {
    return APIClient.post( getBaseUrl({path:'content/popular_destination'}), data);
  },
  
  update: (data?: any): Promise<AxiosResponse<PopularDestinationsData[]>> => {
    return APIClient.put( getBaseUrl({path:'content/popular_destination'}), data);
  },
  delete: (data?: any): Promise<AxiosResponse<{}>> => {
    return APIClient.delete(getBaseUrl({path:'content/popular_destination'}), { data:data });
  }
};

export const usePopularDestinationQuery = {
  useGetAll: (
    data?: any  ): UseQueryResult<PopularDestinations, IError> => {
    return useQuery<PopularDestinations, IError>(
      ["get-popular-destinations", data],
      async (): Promise<PopularDestinations> => {
        const res = await PopularDestinationServices.getAll(data);
        return res;
      }
    );
  },
  useGetSingle: (
      identifier: string | string[] | undefined    ): UseQueryResult<PopularDestinationsData[]> => {
      return useQuery<PopularDestinationsData[]>(
        ["get-popular-destination", identifier],
        async (): Promise<PopularDestinationsData[]> => {
          const res = await PopularDestinationServices.getSingle(identifier);
          return res;
        },
        {
          cacheTime:0
        }
      );
    },
  useCreate: (onSuccess:() => void): UseMutationResult<PopularDestinations, IError> => {
    return useMutation<PopularDestinations, IError, unknown>(
      async (data:any): Promise<PopularDestinations> => {
        const res = await PopularDestinationServices.create(data);
        return res?.data;
      },
      {
        onSuccess:onSuccess
      }
    );
  },
  useUpdate: (onSuccess:() => void): UseMutationResult<PopularDestinationsData[], IError> => {
    return useMutation<PopularDestinationsData[], IError, unknown>(
      async (data:any): Promise<PopularDestinationsData[]> => {
        const res = await PopularDestinationServices.update(data);
        return res?.data;
      },
      {
        onSuccess:onSuccess
      }
    );
  },
  useDelete: (onSuccess:(data:any) => void): UseMutationResult<{}, IError> => {
    return useMutation<{}, IError, unknown>(
      async (data:any): Promise<{}> => {
        const res = await PopularDestinationServices.delete(data);
        return res;
      },
      {
        onSuccess:onSuccess
      },
      
    );
  }
};

export default usePopularDestinationQuery;
