import * as React from 'react';
import {
    Card,
    CardBody,
    Button,
} from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import RemotePagination from 'shared/components/Pagination';
import { useFaqQuestionQuery } from 'services/faq';
import * as FeatherIcon from 'react-feather';
import { useSelector } from 'react-redux';
interface IProps {

}

const Questions: React.FC<IProps>= (props) =>  {
    let navigate = useNavigate();
    function UpdateQuestions(cell:any, row:any) {
        return (
           <Button color="outline-info"> <FeatherIcon.Edit className="icon-xs icon-dual"></FeatherIcon.Edit></Button>
        );
      }
      function ViewContents(cell:any, row:any) {
        return <div dangerouslySetInnerHTML={{__html: row.content}}></div>
        
      }
    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
        },
        {
          dataField: 'category_id',
          text: 'Category ',
        },
        {
          dataField: 'priority',
          text: 'Priority',
        },
        {
            dataField: 'title',
            text: 'Title ',
        },
        {
          dataField: 'content',
          text: 'Content',
          formatter: ViewContents,
        },
        {
            dataField: 'unique_identifier',
            text: 'Edit',
            formatter: UpdateQuestions,
            events: {
                onClick: (e:any, column:any, columnIndex:any, row:any, rowIndex:any) => {
                  navigate(`${row.unique_identifier}/edit`, { replace: false });
                //   alert('Click on Product ID field');
                }
            }
        }
    ];
    const { language } = useSelector((state:any) => state.LangReducer)
   
    const [page,setPage] = React.useState(0)
    const {
        data: Questions,
    } = useFaqQuestionQuery.useGetAll({
        query:{
            'language_id':language,
            'limit':10,
            'prev':page * 10
        }
    });
    const  handleTableChange = (type:any,{page,sizePerPage}:any) => {
        setPage(page-1)
    }

    const sortedCategories = Questions?.objects
    ? [...Questions.objects].sort((a, b) => a.priority - b.priority)
    : [];

    return (
        <>
        <Card className="mt-3">
          <CardBody>
            <Link to="/wadmin/faq-questions/create">
              <Button color="primary" type="submit">
                Create question
              </Button>
            </Link>
          </CardBody>
        </Card>
    {Questions?.objects && Questions?.objects.length ? (
      <>
        <Card>
          <CardBody>
            <RemotePagination
              data={sortedCategories}
              page={page + 1}
              sizePerPage={Questions?.meta.limit}
              totalSize={
                (Questions?.meta.limit as number) *
                (Questions?.meta.max_page as number)
              }
              keyField={""}
              columns={columns}
              onTableChange={handleTableChange} // onTableChange={ this.handleTableChange }
            />
          </CardBody>
        </Card>
      </>
    ) : (
      <div className='d-flex align-items-center justify-content-center flex-column'>
        <h4>No content</h4>
        <img src="https://cdn-icons-png.flaticon.com/512/7303/7303353.png" alt="" width='50px' />
      </div>
    )}
  </>
      
    )
} 

export default Questions;
