import * as React from 'react';
import {
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
} from 'reactstrap';
import { useForm } from "react-hook-form";
interface IProps {

}

const CreateUser: React.FC<IProps>= (props) =>  {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const {ref:keyRef,...key} = register('key', { required: true })
    const {ref:valueRef,...value} = register('value', { required: true })
    const {ref:langIdRef,...language} = register('language', { required: true })

    const onSubmit = (data:{}) => {
        console.log(data);
    };


    return (
   
    <Card className='mt-3'>
        
            <CardBody>
                
                <h4 className="header-title">Users</h4>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup row>
                        <Label for="key" sm={3}>
                            Key
                        </Label>
                        <Col sm={9}>
                            <Input id="key"  type="text"  {...key} innerRef={keyRef}   placeholder="Enter key" />
                              {errors.key && <span>This field is required</span>}
                        </Col>
                    </FormGroup>

                   <FormGroup row>
                        <Label for="value" sm={3}>
                            Value
                        </Label>
                        <Col sm={9}>
                            <Input id="value"  type="text"  {...value} innerRef={valueRef} placeholder="Enter value" />
                            {errors.value && <span>This field is required</span>}
                        </Col>
                    </FormGroup> 
                    <FormGroup row>
                        <Label for="value" sm={3}>
                            Value
                        </Label>
                        <Col sm={9}>
                            <Input id="language"  type="number"  {...language} innerRef={langIdRef} placeholder="Enter language" />
                            {errors.language && <span>This field is required</span>}
                        </Col>
                    </FormGroup> 

                    <Button color="primary" type="submit">
                        Submit
                    </Button>
                </Form>
            </CardBody>
        </Card>
      
    )
} 

export default CreateUser;
