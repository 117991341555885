import * as React from "react";
import { useFaqCategoriesQuery } from "services/faq";
import { Modify } from "../../../../utils/functions";
import Select from 'react-select';
import { StateManagerProps } from "react-select/dist/declarations/src/stateManager";
import { FaqCategoryData } from "shared/models/FormEntity";
import { useSelector } from "react-redux";

export interface ISelectFaqCategoryProps extends StateManagerProps {
  getValue?: any
};

const SelectFaqCategory: React.FunctionComponent<ISelectFaqCategoryProps> = (props) => {
    const {getValue} = props;
    const { language } = useSelector((state:any) => state.LangReducer);

    const {
        data: faqCategories,
        isLoading,
    } = useFaqCategoriesQuery.useGetAll({
        query: {
            'language_id':language
        }
    });

    const getCategoryOptions = (categories:FaqCategoryData[] | undefined) => {
    return categories?.map(category => {
            return {
               label: category.title,
               value: category.id
            }
        })
    }
    return <Select
        className="basic-single"
        classNamePrefix="select"
        isLoading={isLoading}
        name="color"
        value={getCategoryOptions(faqCategories?.objects)?.find(c => c.value === getValue)}
        options={Modify.selectOptionsFaqCategory(faqCategories?.objects|| [])}
        {...props}
        />
}

export default SelectFaqCategory;