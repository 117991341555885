import * as React from 'react';
import {
    Card,
    CardBody,
    Button,
} from 'reactstrap';

import { Link, useNavigate } from 'react-router-dom';
import RemotePagination from '../../shared/components/Pagination';
import useSnippetQuery from 'services/snippets';
import * as FeatherIcon from 'react-feather';
import toast, { Toaster } from 'react-hot-toast';
import moment from 'moment';
import { useSelector } from 'react-redux';

interface IProps {

}

const Snippets: React.FC<IProps>= (props) =>  {
    let navigate = useNavigate();
    const [page,setPage] = React.useState(0)
    const onDeleteSubmit = () => {
        toast('Template variable has been successfully deleted.', {
            duration: 2000,
            position: 'top-center',
            icon: '👍🏻',
          });
    }
    function UpdateSnippet(cell:any, row:any) {
        return (
           <Button color="outline-info"> <FeatherIcon.Edit className="icon-xs icon-dual"></FeatherIcon.Edit></Button>
        );
    }
    function CreatedAt(row:any) {
        return (
            <span>{moment(row.created_at).format("D-MMM-YYYY, h:mm a")}</span>
        );
    }
    function UpdatedAt(row:any) {
        return (
            <span>{moment(row.updated_at).format("D-MMM-YYYY, h:mm a")}</span>
        );
    }
      function DeleteSnippet() {
        return (
           <Button color="outline-danger"> <FeatherIcon.Trash className="icon-xs icon-dual"></FeatherIcon.Trash></Button>
        );
    }
    const { language } = useSelector((state:any) => state.LangReducer);
    const { search_text } = useSelector((state:any) => state.SearchReducer);

    const {
        data: Snippet,
    } = useSnippetQuery.useGetAll(
       {
            'language_id':3,
            'limit':10,
            'prev':page * 10,
            ...(search_text ? {"search_key": search_text} : "")
       }
    );
    const {
        mutate: mutateSnippetDelete,
    } = useSnippetQuery.useDelete(() => onDeleteSubmit());
    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
        },
        {
            dataField: 'name',
            text: 'Name',
        },
        {
            dataField: 'placement',
            text: 'Placement',
        },
        {
            dataField: 'snippet_type',
            text: 'Type',
        },
        {
            dataField: 'created_at',
            formatter:CreatedAt,
            text: 'Created at',
        },
        {
            dataField: 'updated_at',
            formatter:UpdatedAt,
            text: 'Updated at',
        },
        {
            dataField: 'unique_identifier',
            text: 'Edit',
            formatter: UpdateSnippet,
            events: {
                onClick: (e:any, column:any, columnIndex:any, row:any, rowIndex:any) => {
                  navigate(`${row.id}/edit`, { replace: false });
                }
            }
        },
        {
            dataField: 'delete',
            text: 'Delete',
            formatter: DeleteSnippet,
            events: {
                onClick: (e:any, column:any, columnIndex:any, row:any, rowIndex:any) => {
                    if (window.confirm("Do you really want to delete this template variable 😳?") === true) {
                        mutateSnippetDelete(row.id)
                      } else {
                       
                      }
                }
            }
        }
    ];
   
   
    const  handleTableChange = (type:any,{page,sizePerPage}:any) => {
        setPage(page-1)
    }

    return (
        <>
            <Card className='mt-3'>
             <Toaster />
                <CardBody>
                    <Link to="/wadmin/snippets/create">
                        <Button color="primary" type="submit">
                            Create Snippet
                        </Button>
                    </Link>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <RemotePagination
                        data={Snippet?.objects || []}
                        page={page+1}
                        sizePerPage={Snippet?.meta.limit}
                        totalSize={ (Snippet?.meta.limit as number) * (Snippet?.meta.max_page as number)} 
                        keyField={''} columns={columns}
                        onTableChange={handleTableChange}               // onTableChange={ this.handleTableChange }
                    />

                </CardBody>
            </Card>
        </>
   
      
    )
} 

export default Snippets;
