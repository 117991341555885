import { AxiosResponse } from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { Users, UsersData } from "../../shared/models/FormEntity";
import { APIClient } from "../../utils/api/client";
import { IApiParams, IError } from "../../utils/models";
import Endpoints, { getBaseUrl } from "../endpoints";


export interface IGetUsersConfig {
}


const UsersServices = {
  getAll: (data?: any): Promise<Users> => {
    const params: IApiParams<null, null, null> = {
      query: { ...data },
    };
    return APIClient.get(Endpoints.users.getAll(params));
  },
  getSingle: (data: string | string[] | undefined): Promise<UsersData[]> => {
    return APIClient.get(Endpoints.users.getSingle(data));
  },
  create: (data?: any): Promise<AxiosResponse<Users>> => {
    return APIClient.post( getBaseUrl({path:'users/user_list'}), data);
  },
  update: (data?: any): Promise<AxiosResponse<UsersData[]>> => {
    return APIClient.put( getBaseUrl({path:'users/user_list'}), data);
  },
  delete: (data?: any): Promise<AxiosResponse<{}>> => {
    return APIClient.delete(Endpoints.users.delete(),{ data:data });
  },
};

export const useUsersQuery = {
  useGetAll: (
    data?: any  ): UseQueryResult<Users, IError> => {
    return useQuery<Users, IError>(
      ["get-users", data],
      async (): Promise<Users> => {
        const res = await UsersServices.getAll(data);
        return res;
      }
    );
  },
  useGetSingle: (
      identifier: string | string[] | undefined    ): UseQueryResult<UsersData[]> => {
      return useQuery<UsersData[]>(
        ["get-user", identifier],
        async (): Promise<UsersData[]> => {
          const res = await UsersServices.getSingle(identifier);
          return res;
        },
        {
          cacheTime:0
        }
      );
    },
  useCreate: (onSuccess:() => void): UseMutationResult<Users, IError> => {
    return useMutation<Users, IError, unknown>(
      async (data:any): Promise<Users> => {
        const res = await UsersServices.create(data);
        return res?.data;
      },
      {
        onSuccess:onSuccess
      }
    );
  },
  useUpdate: (onSuccess:() => void): UseMutationResult<UsersData[], IError> => {
    return useMutation<UsersData[], IError, unknown>(
      async (data:any): Promise<UsersData[]> => {
        const res = await UsersServices.update(data);
        return res?.data;
      },
      {
        onSuccess:onSuccess
      }
    );
  },
  useDelete: (onSuccess:(data:any) => void): UseMutationResult<{}, IError> => {
    return useMutation<{}, IError, unknown>(
      async (data:any): Promise<{}> => {
        const res = await UsersServices.delete(data);
        return res;
      },
      {
        onSuccess:onSuccess
      },
      
    );
  },
};

export default useUsersQuery;
