import * as React from "react";
import Select from 'react-select';
import { StateManagerProps } from "react-select/dist/declarations/src/stateManager";

export interface ISelectPlacementProps extends StateManagerProps {
  getValue?: any
}
export interface PlacementOptionData { value: string }

const SelectPlacement: React.FunctionComponent<ISelectPlacementProps> = (props) => {
  const {getValue} = props;


  const getPlacementOptions = (placements: PlacementOptionData[] | undefined) => {
    return placements?.map(placement => {
         return {
           label: placement.value,
           value: placement.value
         }
    })
}

const options = [
    { label: "</head>", value: "</head>" },
    { label: "</body>", value: "</body>" }
]
  return <Select
        className="basic-single"
        classNamePrefix="select"
        // isLoading={isLoading}
        name="color"
        placeholder="Select Placement (Required)"
        options={options}
        value={getPlacementOptions(options)?.find(c => c.value == getValue)}
        {...props}
        />
};

export default SelectPlacement;
