import * as React from 'react';
import {
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Row,
} from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import { useFieldArray, useForm, Controller } from "react-hook-form";
import useSeoTextQuery from '../../../services/seo_texts';
import SelectLanguage from '../../../shared/components/Inputs/SelectLanguage';
import { useNavigate, useParams } from 'react-router-dom';
import NavTabsItem from 'shared/components/Tabs';
interface IProps {

}
const EditSeoTexts: React.FC<IProps> = (props) => {
    const params = useParams();
    let navigate = useNavigate();
    const {
        data: seoTexts,
        isLoading
    } = useSeoTextQuery.useGetSingle(params.id)
    const [activeTab, setActiveTab] = React.useState('1');
    const { control, handleSubmit, setValue, formState: { errors } } = useForm();
    const { fields, append } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "seo_texts", // unique name for your Field Array
    });
    React.useEffect(() => {
        if (seoTexts) {
            seoTexts.forEach((seoText, index) => {
                append(seoText)
            })
        }
    }, [seoTexts])
    const onSuccessSubmit = () => {
        toast('Seo texts updated', {
            duration: 2000,
            position: 'top-center',
            icon: '👍🏻',
        });
        setTimeout(() => {
            navigate(-1)
        }, 1000);
    }
    const {
        mutate: mutateSeoTextUpdate,
    } = useSeoTextQuery.useUpdate(onSuccessSubmit);
    const onSubmit = (data: any) => {
        const { seo_texts } = data
        seo_texts.map(
            (el: any) => {
                delete el['unique_identifier']
            }
        )
        mutateSeoTextUpdate(seo_texts)
    };

    return (
        <>
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <Card className='mt-3'>
                    <Toaster />
                    <CardBody>
                        <h4 className="header-title">Admins</h4>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Nav tabs>
                                {fields?.map((i: any, index) => (
                                        <NavItem key={i.id}>
                                            <NavLink
                                                className={activeTab === `${index + 1}` ? "active" : ""}
                                                onClick={function noRefCheck() {
                                                    setActiveTab(`${index + 1}`);
                                                }}
                                            >
                                                <NavTabsItem item={i}/>
                                            </NavLink>
                                        </NavItem>
                                ))}
                            </Nav>
                            <TabContent activeTab={activeTab}>

                                {fields?.map((i: any, index) => (
                                    <TabPane tabId={(index + 1).toString()} key={i.id}>
                                        <Row>
                                            <Col sm="12">
                                                <div>
                                                    <FormGroup row>
                                                        <Label for="key" sm={3}>
                                                            Key
                                                        </Label>
                                                        <Col sm={9}>
                                                            <Controller
                                                                name={`seo_texts.${index}.key`}
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <Input {...field} type="text" id="text" />
                                                                )}
                                                            />
                                                            {errors.key && <span>This field is required</span>}
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row >
                                                        <Label for="value" sm={3}>
                                                            Value
                                                        </Label>

                                                        <Col sm={9}>
                                                            <Controller
                                                                name={`seo_texts.${index}.value`}
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <Input {...field} type="text" id="text" />
                                                                )}
                                                            />
                                                            {errors.key && <span>This field is required</span>}
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row >
                                                        <Label for="language_id" sm={3}>
                                                            Language
                                                        </Label>
                                                        <Col sm={9}>
                                                            <Controller
                                                                name={`seo_texts.${index}.language_id`}
                                                                control={control}
                                                                render={({ field: { value } }) => (
                                                                    <SelectLanguage getValue={value} onChange={(value: any) => {
                                                                        setValue(`seo_texts.${index}.language_id`, value.value)
                                                                    }}
                                                                    />
                                                                )}
                                                            />

                                                        </Col>
                                                    </FormGroup>
                                                </div>
                                            </Col>

                                        </Row>
                                    </TabPane>

                                ))}
                            </TabContent>

                            <Button color="primary" type="submit">
                                Submit
                            </Button>
                        </Form>
                    </CardBody>
                </Card>
            )}
        </>
    )
}

export default EditSeoTexts;
