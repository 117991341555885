import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Card,
    CardBody,
    Button,
} from 'reactstrap';
import useTeamMemberQuery from '../../services/team_members';
import RemotePagination from '../../shared/components/Pagination';
import * as FeatherIcon from 'react-feather';
import toast, { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';

interface IProps {

}

const TeamMembers: React.FC<IProps>= () =>  {
    let navigate = useNavigate();
    const onDeleteSubmit = () => {
        toast('Team member has been successfully deleted.', {
            duration: 2000,
            position: 'top-center',
            icon: '👍🏻',
          });
    }
    function DeleteTeamMember(cell:any, row:any) {
         return (
            <Button color="outline-danger"> <FeatherIcon.Trash className="icon-xs icon-dual"></FeatherIcon.Trash></Button>
         );
       }
    function UpdateTeamMember(cell:any, row:any) {
        return (
           <Button color="outline-info"> <FeatherIcon.Edit className="icon-xs icon-dual"></FeatherIcon.Edit></Button>
        );
      }
      const {
        mutate: mutateTeamMemberDelete,
    } = useTeamMemberQuery.useDelete(() => onDeleteSubmit());
    const columns = [
        {
            dataField: 'id',
            text: 'ID',
        },
        {
            dataField: 'priority',
            text: 'Priority',
            sort: true,
        },
        {
            dataField: 'surname',
            text: 'Surname',
        },
        {
            dataField: 'name',
            text: 'Name',
        },
        {
            dataField: 'position',
            text: 'Position',
        },
        {
            dataField: 'unique_identifier',
            text: 'Edit',
            formatter: UpdateTeamMember,
            events: {
                onClick: (e:any, column:any, columnIndex:any, row:any) => {
                  navigate(`${row.unique_identifier}/edit`, { replace: false });
                //   alert('Click on Product ID field');
                }
            }
        }, 
         {
            dataField: 'delete',
            text: 'Delete',
            formatter: DeleteTeamMember,
            events: {
                onClick: (e:any, column:any, columnIndex:any, row:any) => {
                    if (window.confirm("Do you really want to delete this office 😳?") === true) {
                        mutateTeamMemberDelete(
                            {
                                'id':row.id
                            }
                        )
                      } 
                }
            }
        }

    ];
   
    const [page,setPage] = React.useState(0)
    const { language } = useSelector((state:any) => state.LangReducer);
    const { search_text } = useSelector((state:any) => state.SearchReducer);

    const {
        data: TeamMembers    } = useTeamMemberQuery.useGetAll({
        path:'company/team',
        query:{
            'language_id':language,
            'limit':10,
            'prev':page * 10,
            ...(search_text ? {"search_key": search_text} : "")
        }
    });
    const  handleTableChange = (type:any,{page}:any) => {
        setPage(page-1)
        
    }

    return (
        <>
            <Card className='mt-3'>
            <Toaster />
                <CardBody>
                    <Link to="/wadmin/team-members/create">
                        <Button color="primary" type="submit">
                            Create Team Members
                        </Button>
                    </Link>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <RemotePagination
                        data={TeamMembers?.objects || []}
                        page={page+1}
                        sizePerPage={TeamMembers?.meta.limit}
                        totalSize={ (TeamMembers?.meta.limit as number) * (TeamMembers?.meta.max_page as number)} keyField={''} columns={columns}
                        onTableChange={handleTableChange}               // onTableChange={ this.handleTableChange }
                    />
                </CardBody>
            </Card>
       </>
      
    )
} 

export default TeamMembers;
