import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

// export const configureStore = (): any => {
//     // const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//     const store = createStore(reducers,applyMiddleware(sagaMiddleware));
//     sagaMiddleware.run(appMenuSaga);
//     return store;
// }
declare global {
    interface Window {
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}
export function configureStore(initialState?: {}) {
    const composeEnhancers =  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__  || compose

    const store = createStore(reducers, initialState,composeEnhancers(applyMiddleware(...middlewares)));
    sagaMiddleware.run(sagas);
    return store;
}