import React, { useState } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import SelectDomain from 'shared/components/Inputs/SelectDomain';
interface IProps{

}
const DomainsModal: React.FC<IProps>= (props) =>  {
    const [show, setShow] = useState(false);
    const [domain_value, setDomainValue] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <Modal
        isOpen={show}
        >
        <ModalHeader toggle={function noRefCheck(){}}>
        Select Domain
        </ModalHeader>
        <ModalBody>
           <div>
           <SelectDomain onChange={(value:any) =>{
                // setDomainValue(value)
            }}/>
           </div>
        </ModalBody>
        <ModalFooter>
            <Button
            color="primary"
            onClick={function noRefCheck(){}}
            >
            Do Something
            </Button>
            {' '}
            <Button onClick={function noRefCheck(){}}>
            Cancel
            </Button>
        </ModalFooter>
        </Modal>
    )
}

export default DomainsModal
