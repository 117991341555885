import { FC, } from 'react'
import { Navigate, Outlet, Route, RouteProps, } from 'react-router-dom';
// const EpisodeWrapper = (props:RouteProps) => {
//     const {element:Component, ...rest} =props
//     if (!isUserAuthenticated()) {
//         return <Navigate to="/login" />;
//     }
//     else return <Component   />;
// }


const EpisodeWrapper = () => {
    const auth = null; // determine if authorized, from context or however you're doing it

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return auth ? <Outlet /> : <Navigate to="/login" />;
}
const PrivateRoute:FC = (props:RouteProps) =>  {
    const {element:Component, ...rest} =props
    return (
         <Route
             element={<EpisodeWrapper/>}
         />
     );
 }

export default PrivateRoute;
