import { searchAction, searchState, SET_SEARCH_TEXT } from "./types";

const initialState:searchState = {
    search_text: ""
}
const SearchReducer =(state = initialState,action:searchAction)=>{
    switch (action.type) {
        case SET_SEARCH_TEXT:
            return{
                ...state,
                search_text:action.payload
            }
    
        default:
            return state;
    }
}
export default SearchReducer