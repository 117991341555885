import { AxiosResponse } from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { SeoTexts, SeoTextsData } from "../../shared/models/FormEntity";
import { APIClient } from "../../utils/api/client";
import { IError } from "../../utils/models";
import { getBaseUrl } from "../endpoints";
import { queryToString } from "utils/functions";

export interface IGetSeoTextParams {
 
}
export interface IGetSeoTextConfig {
}

const SeoTextServices = {
  getAll: (data?: IGetSeoTextParams): Promise<SeoTexts> => {
    return APIClient.get(getBaseUrl({path:`content/seo_text/${queryToString(data)}`}));
  },
  getSingle: (data: string | string[] | undefined): Promise<SeoTexts[]> => {
    return APIClient.get(getBaseUrl({path:`content/seo_text/${data}`}));
  },
  create: (data?: any): Promise<AxiosResponse<SeoTexts>> => {
    return APIClient.post( getBaseUrl({path:'content/seo_text'}), { ...data });
  },
  update: (data?: any): Promise<AxiosResponse<SeoTextsData[]>> => {
    return APIClient.put( getBaseUrl({path:'content/seo_text'}), data);
  },
  delete: (data?: any): Promise<AxiosResponse<{}>> => {
    return APIClient.delete(getBaseUrl({path:'content/seo_text'}),{ data:data });
  },
};

export const useSeoTextQuery = {
  useGetAll: (
    data?: IGetSeoTextParams  ): UseQueryResult<SeoTexts, IError> => {
    return useQuery<SeoTexts, IError>(
      ["get-seo-text", data],
      async (): Promise<SeoTexts> => {
        const res = await SeoTextServices.getAll(data);
        return res;
      }
    );
  },
  useGetSingle: (
      identifier: string | string[] | undefined    ): UseQueryResult<SeoTexts[]> => {
      return useQuery<SeoTexts[]>(
        ["get-seo-text", identifier],
        async (): Promise<SeoTexts[]> => {
          const res = await SeoTextServices.getSingle(identifier);
          return res;
        },
        {
          cacheTime:0
        }
      );
    },
  useCreate: (onSuccess:() => void): UseMutationResult<SeoTexts, IError> => {
    return useMutation<SeoTexts, IError, unknown>(
      async (data:any): Promise<SeoTexts> => {
        const res = await SeoTextServices.create(data);
        return res?.data;
      },
      {
        onSuccess:onSuccess
      }
    );
  },
  useUpdate: (onSuccess:() => void): UseMutationResult<SeoTextsData[], IError> => {
    return useMutation<SeoTextsData[], IError, unknown>(
      async (data:any): Promise<SeoTextsData[]> => {
        const res = await SeoTextServices.update(data);
        return res?.data;
      },
      {
        onSuccess:onSuccess
      }
    );
  },
  useDelete: (onSuccess:(data:any) => void): UseMutationResult<{}, IError> => {
    return useMutation<{}, IError, unknown>(
      async (data:any): Promise<{}> => {
        const res = await SeoTextServices.delete(data);
        return res;
      },
      {
        onSuccess:onSuccess
      },
      
    );
  },
};

export default useSeoTextQuery;
