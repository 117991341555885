import React, { useState } from 'react'
import {
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Row,
} from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import { useFieldArray, useForm,Controller } from "react-hook-form";
import {
    useNavigate,
    useParams
  } from "react-router-dom";
import useOfficesQuery from '../../../services/offices';
import NavTabsItem from 'shared/components/Tabs';
interface IProps {

}

const EditOffice: React.FC<IProps>= () =>{
  const params = useParams();
  let navigate = useNavigate();

  const { data: Offices,isLoading } = useOfficesQuery.useGetSingle(params.id)

  const [activeTab, setActiveTab] = useState('1');
  const { control, handleSubmit } = useForm();
  const { fields, append } = useFieldArray({
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "offices", // unique name for your Field Array
  });
  React.useEffect(() => {
    if (Offices) {
    Offices.forEach((tm) => {
        append(tm);
      });
    }
  }, [Offices]);
  const onSuccessSubmit = () => {
    toast('Office updated', {
        duration: 2000,
        position: 'top-center',
        icon: '👍🏻',
      });
    setTimeout(() => {
        navigate(-1)
    }, 1000);
}
  
const {
  mutate: mutateOfficesUpdate,
  error
} = useOfficesQuery.useUpdate(onSuccessSubmit);
React.useEffect(() => {
    if (error) {
        toast.error(error.message as string)
    }
}, [error])
const onSubmit = (data: any) => {
  const { offices } =data
  offices.map(
   (el:any)=>
    {
      delete el['unique_identifier']
    }
  )
  mutateOfficesUpdate(offices)
};

  return (
    <>
    {isLoading ? (
        <div>Loading...</div>
        ) : (
        <Card className="mt-3">
        <Toaster />
        <CardBody>
            <h4 className="header-title">Edit office</h4>
            <Form onSubmit={handleSubmit(onSubmit)}>
            <Nav tabs>
                {fields?.map((i: any, index) => (
                <>
                    <NavItem>
                    <NavLink
                        className={activeTab === `${index + 1}` ? "active" : ""}
                        onClick={function noRefCheck() {
                        setActiveTab(`${index + 1}`);
                        }}
                    >
                        <NavTabsItem item={i}/>
                    </NavLink>
                    </NavItem>
                </>
                ))}
            </Nav>
            <TabContent activeTab={activeTab}>
                {fields?.map((i: any, index) => (
                <TabPane tabId={(index + 1).toString()} key={i.id}>
                    <Row>
                    <Col sm="12">
                        <div>
                        <FormGroup row >
                                        <Label for="name" sm={3}>
                                            Name
                                        </Label>
                                        <Col sm={9}>
                                        <Controller
                                            name={`offices.${index}.name`}
                                            control={control}
                                            render={({ field }) => (
                                            <Input {...field} type="text" id="name" />
                                            )}
                                        />
                                        </Col>
                          </FormGroup>
                        <FormGroup row >
                                        <Label for="phone" sm={3}>
                                            Phone
                                        </Label>
                                        <Col sm={9}>
                                        <Controller
                                            name={`offices.${index}.phone`}
                                            control={control}
                                            render={({ field }) => (
                                            <Input {...field} type="text" id="phone" />
                                            )}
                                        />
                                        </Col>
                          </FormGroup>
                          <FormGroup row >
                                        <Label for="office_hours" sm={3}>
                                            Offices hours
                                        </Label>
                                        <Col sm={9}>
                                        <Controller
                                            name={`offices.${index}.office_hours`}
                                            control={control}
                                            render={({ field }) => (
                                            <Input {...field} type="text" id="office_hours" />
                                            )}
                                        />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row >
                                        <Label for="address" sm={3}>
                                        Address
                                        </Label>
                                        <Col sm={9}>
                                        <Controller
                                            name={`offices.${index}.address`}
                                            control={control}
                                            render={({ field }) => (
                                            <Input {...field} type="text" id="address" />
                                            )}
                                        />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row >
                                        <Label for="latitude" sm={3}>
                                        Latitude
                                        </Label>
                                        <Col sm={9}>
                                        <Controller
                                            name={`offices.${index}.latitude`}
                                            control={control}
                                            render={({ field }) => (
                                            <Input {...field} type="text" id="latitude" />
                                            )}
                                        />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row >
                                        <Label for="longitude" sm={3}>
                                        Longitude
                                        </Label>
                                        <Col sm={9}>
                                        <Controller
                                            name={`offices.${index}.longitude`}
                                            control={control}
                                            render={({ field }) => (
                                            <Input {...field} type="text" id="longitude" />
                                            )}
                                        />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row >
                                        <Label for="country_code" sm={3}>
                                        Country code
                                        </Label>
                                        <Col sm={9}>
                                        <Controller
                                            name={`offices.${index}.country_code`}
                                            control={control}
                                            render={({ field }) => (
                                            <Input {...field} type="text" id="country_code" />
                                            )}
                                        />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row >
                                        <Label for="email" sm={3}>
                                        Email
                                        </Label>
                                        <Col sm={9}>
                                        <Controller
                                            name={`offices.${index}.email`}
                                            control={control}
                                            render={({ field }) => (
                                            <Input {...field} type="text" id="email" />
                                            )}
                                        />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row >
                                        <Label for="consultation_hours" sm={3}>
                                        Consultation hours
                                        </Label>
                                        <Col sm={9}>
                                        <Controller
                                            name={`offices.${index}.consultation_hours`}
                                            control={control}
                                            render={({ field }) => (
                                            <Input {...field} type="text" id="consultation_hours" />
                                            )}
                                        />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row >
                                        <Label for="priority" sm={3}>
                                        Priority
                                        </Label>
                                        <Col sm={9}>
                                        <Controller
                                            name={`offices.${index}.priority`}
                                            control={control}
                                            render={({ field }) => (
                                            <Input {...field} type="number" id="priority" />
                                            )}
                                        />
                                        </Col>
                                    </FormGroup>
                        </div>
                    </Col>
                    </Row>
                </TabPane>
                ))}
            </TabContent>
            <Button color="primary" type="submit">
                Submit
            </Button>
            </Form>
        </CardBody>
        </Card>
    )}
    </>
  );
}

export default EditOffice