import * as React from 'react';


import {
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
} from 'reactstrap';
import { useForm } from "react-hook-form";
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import useSnippetQuery from '../../../services/snippets';
import SelectDomain from 'shared/components/Inputs/SelectDomain';
import SelectSnippetType from 'shared/components/Inputs/SelectSnippetType';
import SelectPlacement from 'shared/components/Inputs/SelectPlacement';
interface IProps {

}

const CreateSnippet: React.FC<IProps>= () =>  {
    let navigate = useNavigate();
    const onSuccessSubmit = () => {
        toast('Snippet created', {
            duration: 2000,
            position: 'top-center',
            icon: '👍🏻',
            iconTheme: {
              primary: '#000',
              secondary: '#f2f2f2',
            },
            ariaProps: {
              role: 'status',
              'aria-live': 'polite',
            },
          });
        setTimeout(() => {
            navigate(-1)
        }, 1000);
    }
    const {
        mutate: mutateSnippetCreate,
    } = useSnippetQuery.useCreate(onSuccessSubmit);
     
    const  onSubmit = (data:{}) => {
        mutateSnippetCreate(data)
    };
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const { onChange:nameOnChange, name, ref:nameRef } = register('name', { required: true });
    const { onChange:contentOnChange, name:content, ref:contentRef } = register('content', { required: true });

      
    return (
        <Card className='mt-3'>
            <Toaster />
            <CardBody>
                <h4 className="header-title">Create Snippet</h4>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup row>
                        <Label for="domain_id" sm={3}>
                            Domain
                        </Label>
                        <Col sm={9}>
                            <SelectDomain {...register('domain_id', { required: true })} onChange={(value:any)=>{
                                setValue('domain_id',value.value)
                            }}/>
                            {errors.domain_id && <span>This field is required</span>}
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="snippet_type" sm={3}>
                            Snippet Type
                        </Label>
                        <Col sm={9}>
                            <SelectSnippetType {...register('snippet_type', { required: true })} onChange={(value:any)=>{
                                setValue('snippet_type', value.value)
                            }} />
                            {errors.snippet_type && <span>This field is required</span>}
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="placement" sm={3}>
                            Placement
                        </Label>
                        <Col sm={9}>
                            <SelectPlacement {...register('placement', { required: true })} onChange={(value:any)=>{
                                setValue('placement',value.value)
                            }}/>
                            {errors.placement && <span>This field is required</span>}
                        </Col>
                    </FormGroup>


                    <FormGroup row>
                        <Label for="name" sm={3}>
                            Name
                        </Label>
                        <Col sm={9}>
                            <Input id="name" type="text" name={name} onChange={nameOnChange} innerRef={nameRef} placeholder="Enter name" />
                            {errors.name && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                    
                    <FormGroup row>
                        <Label for="content" sm={3}>
                            Content
                        </Label>
                        <Col sm={9}>
                            <Input id="content" type="textarea" name={content} onChange={contentOnChange} innerRef={contentRef} placeholder="Enter content" />
                            {errors.content && <span>This field is required</span>} 
                        </Col>
                    </FormGroup>
                    
                    <Button color="primary" type="submit">
                        Submit
                    </Button>
                </Form>
                
            </CardBody>
        </Card>
      
    )
} 

export default CreateSnippet;
