import React, { useState } from 'react'
import {
    useParams
  } from "react-router-dom";
import useStaticContentQuery from '../../../services/static_contents';
import { StaticContentsData } from 'shared/models/FormEntity';
import EditStaticContent from 'shared/components/Forms/EditStaticContent';
interface IProps {

}

const EditStaticContents: React.FC<IProps>= () =>{
  const params = useParams();

  const { data: StaticContents,isLoading } = useStaticContentQuery.useGetSingle(params.id)
  const [defaultArray,setDefaultArray] =useState<StaticContentsData[] |null >(null)
  React.useEffect(() => {
      if (StaticContents) {
        setDefaultArray(StaticContents) 
      }
  }, [StaticContents]);
 
 
  return (
    <>
    {isLoading ? (
        <div>Loading page...</div>
        ) : (
          <>
          {defaultArray ? (
            <>
            <EditStaticContent defaultArray={defaultArray} /></>
            ) : (
            <div>Loading data</div>
        )}
          </>
    )}
    </>
  );
}

export default EditStaticContents