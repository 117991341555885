import { AxiosResponse } from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { Translations, TranslationsData } from "../../shared/models/FormEntity";
import { APIClient } from "../../utils/api/client";
import { IError } from "../../utils/models";
import { getBaseUrl } from "../endpoints";

import { queryToString } from "../../utils/functions";

export interface IGetTranslationsConfig {
}


const TranslationServices = {
  getAll: (data?: any): Promise<Translations> => {
    return APIClient.get(getBaseUrl({path:`content/template_variable${queryToString(data)}`}));
  },
  getSingle: (data: string | string[] | undefined): Promise<TranslationsData[]> => {
    return APIClient.get(getBaseUrl({path:`content/template_variable/${data}`}));
  },
  create: (data?: any): Promise<AxiosResponse<Translations>> => {
    return APIClient.post( getBaseUrl({path:'content/template_variable'}), data);
  },
  update: (data?: any): Promise<AxiosResponse<TranslationsData[]>> => {
    return APIClient.put( getBaseUrl({path:'content/template_variable'}), data);
  },
  delete: (data?: any): Promise<AxiosResponse<{}>> => {
    return APIClient.delete(getBaseUrl({path:'content/template_variable'}),{ data:data });
  },
};

export const useTranslationQuery = {
  useGetAll: (
    data?: any,
    meta?: IGetTranslationsConfig
  ): UseQueryResult<Translations, IError> => {
    return useQuery<Translations, IError>(
      ["get-translations", data],
      async (): Promise<Translations> => {
        const res = await TranslationServices.getAll(data);
        return res;
      }
    );
  },
  useGetSingle: (
      identifier: string | string[] | undefined,
      meta?: IGetTranslationsConfig
    ): UseQueryResult<TranslationsData[]> => {
      return useQuery<TranslationsData[]>(
        ["get-translation", identifier],
        async (): Promise<TranslationsData[]> => {
          const res = await TranslationServices.getSingle(identifier);
          return res;
        },
        {
            cacheTime:0
        }
      );
    },
  useCreate: (onSuccess:() => void): UseMutationResult<Translations, IError> => {
    return useMutation<Translations, IError, unknown>(
      async (data:any): Promise<Translations> => {
        const res = await TranslationServices.create(data);
        return res?.data;
      },
      {
        onSuccess:onSuccess
      }
    );
  },
  useUpdate: (onSuccess:() => void): UseMutationResult<TranslationsData[], IError> => {
    return useMutation<TranslationsData[], IError, unknown>(
      async (data:any): Promise<TranslationsData[]> => {
        const res = await TranslationServices.update(data);
        return res?.data;
      },
      {
        onSuccess:onSuccess
      }
    );
  },
  useDelete: (onSuccess:(data:any) => void): UseMutationResult<{}, IError> => {
    return useMutation<{}, IError, unknown>(
      async (data:any): Promise<{}> => {
        const res = await TranslationServices.delete(data);
        return res;
      },
      {
        onSuccess:onSuccess
      },
      
    );
  },
};

export default useTranslationQuery;
