import * as React from 'react';
import useSeoTextQuery from '../../../services/seo_texts';
import {
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
} from 'reactstrap';
import { useForm } from "react-hook-form";
import SelectLanguage from '../../../shared/components/Inputs/SelectLanguage';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

interface IProps {

}
const CreateSeoText: React.FC<IProps>= () =>  {
    let navigate = useNavigate();
    const onSuccessSubmit = () => {
        toast('Seo texts created', {
            duration: 2000,
            position: 'top-center',
            style: {
                // background:'#d9f6ec'
            },
            className: '',
            icon: '👍🏻',
            iconTheme: {
              primary: '#000',
              secondary: '#f2f2f2',
            },
            ariaProps: {
              role: 'status',
              'aria-live': 'polite',
            },
          });
        setTimeout(() => {
            navigate(-1)
        }, 1000);
    }
    const {
        mutate: mutateSeoTextCreate,
        isLoading,
    } = useSeoTextQuery.useCreate(onSuccessSubmit);
     
    const  onSubmit = (data:{}) => {
        mutateSeoTextCreate(data)
    };
  
    const { register, handleSubmit, setValue,formState: { errors } } = useForm();
    const {ref:keyRef,...key} = register('key', { required: true })
    const {ref:valueRef,...value} = register('value', { required: true })


    return (
   
    <Card className='mt-3'>
            <Toaster />
            <CardBody>
                <h4 className="header-title">Seo texts</h4>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup row>
                        <Label for="key" sm={3}>
                            Key
                        </Label>
                        <Col sm={9}>
                            <Input id="key"  type="text"  {...key} innerRef={keyRef}   placeholder="Enter key" />
                              {errors.key && <span>This field is required</span>}
                        </Col>
                    </FormGroup>

                   <FormGroup row>
                        <Label for="value" sm={3}>
                            Value
                        </Label> 
                        <Col sm={9}>
                            <Input id="value"  type="text" {...value}  innerRef={valueRef} placeholder="Enter value" />
                            {errors.value && <span>This field is required</span>}
                        </Col>
                    </FormGroup> 
                    <FormGroup row>
                        <Label for="language_id" sm={3}>
                            Select language
                        </Label>
                        <Col sm={9}>
                            <SelectLanguage {...register('language_id')} onChange={(value:any)=>{
                                setValue('language_id',value.value)
                            }}/>
                        </Col>
                    </FormGroup> 

                    <Button color="primary" type="submit">
                        {
                            isLoading ? 'Loading...' : 'Create'
                        }
                    </Button>
                </Form>
            </CardBody>
        </Card>
      
    )
} 

export default CreateSeoText;
