import axios, {AxiosInstance} from 'axios';
import { getLoggedInUser } from '../../services/auth/authUtils';
import { IError } from '../models';
import { errorWrapper } from './error';
const user = getLoggedInUser();

export const APIClient: AxiosInstance = axios.create({
    // baseURL: API_BASE_URL,
    headers: {'Authorization': user ? `Bearer ${user.access_token}`:''},
});

APIClient.interceptors.response.use(
    (response: { data: any; }) => {
        return response.data;
    },
    async (error: any) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        const wrappedError: IError = await errorWrapper(error);
        return Promise.reject(wrappedError);
    },
);
