import * as React from 'react';
import {
    Card,
    CardBody,
    Button,
} from 'reactstrap';

import { Link, useNavigate } from 'react-router-dom';
import RemotePagination from '../../shared/components/Pagination';
import useOfficesQuery from '../../services/offices';
import * as FeatherIcon from 'react-feather';
import toast, { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';

// import { Link } from 'react-feather';
interface IProps {

}

const Offices: React.FC<IProps>= (props) =>  {
    let navigate = useNavigate();
    const [page,setPage] = React.useState(0)
    const onDeleteSubmit = () => {
        toast('Office has been successfully deleted.', {
            duration: 2000,
            position: 'top-center',
            icon: '👍🏻',
          });
    }
    const { language } = useSelector((state:any) => state.LangReducer);
    const { search_text } = useSelector((state:any) => state.SearchReducer);

    const {
        data: Offices,
    } = useOfficesQuery.useGetAll({
        path:'company/offices',
        query:{
            'language_id':language,
            'limit':10,
            'prev':page * 10,
            ...(search_text ? {"search_key": search_text} : "")
        }
    });
    const {
        mutate: mutateOfficeDelete,
    } = useOfficesQuery.useDelete(() => onDeleteSubmit());
    function UpdateOffice(cell:any, row:any) {
        return (
           <Button color="outline-info"> <FeatherIcon.Edit className="icon-xs icon-dual"></FeatherIcon.Edit></Button>
        );
      }
      function DeleteOffice(cell:any, row:any) {
         return (
            <Button color="outline-danger"> <FeatherIcon.Trash className="icon-xs icon-dual"></FeatherIcon.Trash></Button>
         );
       }
     
    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
        },
        {
            dataField: 'name',
            text: 'Name',
        },
        {
            dataField: 'phone',
            text: 'Phone',
        },
        {
            dataField: 'latitude',
            text: 'Latitude ',
        },
        {
            dataField: 'longitude',
            text: 'Longitude ',
        },
        {
            dataField: 'unique_identifier',
            text: 'Edit',
            formatter: UpdateOffice,
            events: {
                onClick: (e:any, column:any, columnIndex:any, row:any, rowIndex:any) => {
                  navigate(`${row.unique_identifier}/edit`, { replace: false });
                //   alert('Click on Product ID field');
                }
            }
        },
        {
            dataField: 'delete',
            text: 'Delete',
            formatter: DeleteOffice,
            events: {
                onClick: (e:any, column:any, columnIndex:any, row:any, rowIndex:any) => {
                    if (window.confirm("Do you really want to delete this office 😳?") === true) {
                        mutateOfficeDelete(
                            {
                                'id':row.id
                            }
                        )
                      } 
                }
            }
        }
    ];
   

    const  handleTableChange = (type:any,{page,sizePerPage}:any) => {
        setPage(page-1)
    }


    return (
        <>
            <Card className='mt-3'>
             <Toaster />
                <CardBody>
                    <Link to="/wadmin/offices/create">
                        <Button color="primary" type="submit">
                            Create Offices
                        </Button>
                    </Link>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <RemotePagination
                        data={Offices?.objects || []}
                        page={page+1}
                        sizePerPage={Offices?.meta.limit}
                        totalSize={ (Offices?.meta.limit as number) * (Offices?.meta.max_page as number)} keyField={''} columns={columns}
                        onTableChange={handleTableChange}               // onTableChange={ this.handleTableChange }
                    />

                </CardBody>
            </Card>
        </>
   
      
    )
} 

export default Offices;
