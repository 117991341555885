

/**
 * Flatten the nested list of menu items
 */
 let uuid = 1;

 const assignIdAndParent = (menuItems:any, parentId?:number) => {
     menuItems = menuItems || [];
     menuItems.forEach((item:any) => {
         const id = item.id || uuid;
         uuid += 1;
         item.id = id;
         item.parentId = item.parentId || parentId;
         item.active = false;
 
         if (typeof item.children !== 'undefined') {
             assignIdAndParent(item.children, id);
         }
     });
 };
 
 export default (menuItems:any):any => {
     assignIdAndParent(menuItems);
     return menuItems;
 };
 