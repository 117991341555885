// @flow

import { combineReducers } from 'redux';
import AppMenu from './appMenu/reducers';
import Auth from './auth/reducers';
import LangReducer from './language/reducer';
import SearchReducer from './search/reducers';


export default combineReducers({
    AppMenu,
    Auth,
    LangReducer,
    SearchReducer
});
