import { AxiosResponse } from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { MessageData, Messages, MessageTypes, MessageTypesData } from "../../shared/models/FormEntity";
import { APIClient } from "../../utils/api/client";
import {  IError } from "../../utils/models";
import { getBaseUrl } from "../endpoints";

import { queryToString } from "../../utils/functions";

export interface IGetMessageTypesConfig {
}


const MessageTypeServices = {
  getAll: (data?: any): Promise<MessageTypes> => {
    return APIClient.get(getBaseUrl({path:`company/contact/message_types${queryToString(data.query)}`}));

  },
  getSingle: (data: string | string[] | undefined): Promise<MessageTypesData[]> => {
    return APIClient.get(getBaseUrl({path:`company/contact/message_type/${data}`}));
  },
  create: (data?: any): Promise<AxiosResponse<MessageTypes>> => {
    return APIClient.post( getBaseUrl({path:'company/contact/message_types'}), data);
  },
  
  update: (data?: any): Promise<AxiosResponse<MessageTypesData[]>> => {
    return APIClient.put( getBaseUrl({path:'company/contact/message_type'}), data);
  },
  delete: (data?: any): Promise<AxiosResponse<{}>> => {
    return APIClient.delete(getBaseUrl({ path: "company/contact/message_type" }), {
      data: data,
    });
  },
};
const MessagesServices = {
  getAll: (data?: any): Promise<Messages> => {
    return APIClient.get(getBaseUrl({path:`company/contact/messages${queryToString(data.query)}`}));
  },
  getSingle: (data: string | string[] | undefined): Promise<MessageData[]> => {
    return APIClient.get(getBaseUrl({path:`company/contact/messages/${data}`}));
  }
}
export const useMessageTypeQuery = {
  useGetAll: (
    data?: any,
    meta?: IGetMessageTypesConfig
  ): UseQueryResult<MessageTypes, IError> => {
    return useQuery<MessageTypes, IError>(
      ["get-messages-types", data],
      async (): Promise<MessageTypes> => {
        const res = await MessageTypeServices.getAll(data);
        return res;
      }
    );
  },
  useGetSingle: (
      identifier: string | string[] | undefined,
      meta?: IGetMessageTypesConfig
    ): UseQueryResult<MessageTypesData[]> => {
      return useQuery<MessageTypesData[]>(
        ["get-messages-type", identifier],
        async (): Promise<MessageTypesData[]> => {
          const res = await MessageTypeServices.getSingle(identifier);
          return res;
        },
        {
          cacheTime:0
        }
      );
    },
    useCreate: (onSuccess:() => void): UseMutationResult<MessageTypes, IError> => {
    return useMutation<MessageTypes, IError, unknown>(
      async (data:any): Promise<MessageTypes> => {
        const res = await MessageTypeServices.create(data);
        return res?.data;
      },
      {
        onSuccess:onSuccess
      }
    );
  },
  useUpdate: (onSuccess:() => void): UseMutationResult<MessageTypesData[], IError> => {
    return useMutation<MessageTypesData[], IError, unknown>(
      async (data:any): Promise<MessageTypesData[]> => {
        const res = await MessageTypeServices.update(data);
        return res?.data;
      },
      {
        onSuccess:onSuccess
      }
    );
  },
  useDelete: (
    onSuccess: (data: any) => void
  ): UseMutationResult<{}, IError> => {
    return useMutation<{}, IError, unknown>(
      async (data: any): Promise<{}> => {
        const res = await MessageTypeServices.delete(data);
        return res;
      },
      {
        onSuccess: onSuccess,
      }
    );
  },
};
export const useMessagesQuery = {
  useGetAll: (
    data?: any,
    meta?: IGetMessageTypesConfig
  ): UseQueryResult<Messages, IError> => {
    return useQuery<Messages, IError>(
      ["get-messages", data],
      async (): Promise<Messages> => {
        const res = await MessagesServices.getAll(data);
        return res;
      }
    );
  },
  useGetSingle: (
      identifier: string | string[] | undefined,
      meta?: IGetMessageTypesConfig
    ): UseQueryResult<MessageData[]> => {
      return useQuery<MessageData[]>(
        ["get-message", identifier],
        async (): Promise<MessageData[]> => {
          const res = await MessagesServices.getSingle(identifier);
          return res;
        },
        {
          enabled:true
        }
      );
    },
  }
export default useMessageTypeQuery;
