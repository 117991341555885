// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';


import { LOGIN_USER,LOGOUT_USER } from './constants';

import {
    loginUserSuccess,
    loginUserFailed,
    
} from './actions';
import { fetchJSON2 } from '../../services/auth/api';

/**
 * Sets the session
 * @param {*} user
 */
const setSession = (user:any) => {
    let cookies = new Cookies();
    if (user) cookies.set('user', JSON.stringify(user), { path: '/' });
    else cookies.remove('user', { path: '/' });
};
/**
 * Login the user
 * @param {*} payload - username and password
 */
 interface IState{
    payload:
    { 
        email:string,
        password:string
    },history:any 
}
function* login(actions:IState) :any{
    const options = {
        body: JSON.stringify(actions.payload),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response:any = yield call(fetchJSON2, '/users/signin', options);
        setSession(response);
        yield put(loginUserSuccess(response));
        actions.history('/wadmin/admins',{replace:true})
        //TODO redirect to dashboard without reload
        window.location.reload()
    } catch (error:any) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(loginUserFailed(message));
        setSession(null);
    }
}

/**
 * Logout the user
 * @param {*} param0
 * 
 */
 function* logout({ payload: { history } }:any) {
    try {
        setSession(null);
        yield call(() => {
            history('/wadmin/login');
            window.location.reload()
        });
    } catch (error) {}
}


export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER,login as any);
}
export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}


function* authSaga() {
    yield all([fork(watchLoginUser),fork(watchLogoutUser)]);
}

export default authSaga;
