import React, { useState } from 'react'
import {
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Row,
} from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import { useFieldArray, useForm,Controller } from "react-hook-form";
import {
    useNavigate,
    useParams
  } from "react-router-dom";
import useSnippetQuery from 'services/snippets';
import NavTabsItem from 'shared/components/Tabs';
import SelectDomain from 'shared/components/Inputs/SelectDomain';
import SelectSnippetType from 'shared/components/Inputs/SelectSnippetType';
import SelectPlacement from 'shared/components/Inputs/SelectPlacement';
interface IProps {

}

const EditSnippet: React.FC<IProps>= () =>{
  const params = useParams();
  let navigate = useNavigate();

  const { data: Snippet, isLoading } = useSnippetQuery.useGetSingle(params.id)

  const [activeTab, setActiveTab] = useState('1');
  const { control, handleSubmit, setValue, formState: { errors } } = useForm();
  const { fields, append } = useFieldArray({
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "snippets", // unique name for your Field Array
  });
  React.useEffect(() => {
    if (Snippet) {
    append(Snippet);
    }
  }, [Snippet]);
  const onSuccessSubmit = () => {
    toast('Snippet updated', {
        duration: 2000,
        position: 'top-center',
        icon: '👍🏻',
      });
    setTimeout(() => {
        navigate(-1)
    }, 1000);
}
  
const {
  mutate: mutateSnippetUpdate,
} = useSnippetQuery.useUpdate(onSuccessSubmit);
const onSubmit = (data: any) => {
  const { snippets } = data
  snippets.map(
   (el:any)=>
    {
      delete el['id']
    }
  )
  snippets[0].snippet_id = params.id;
  mutateSnippetUpdate(snippets)
};

  return (
    <>
    {isLoading ? (
        <div>Loading...</div>
        ) : (
        <Card className="mt-3">
        <Toaster />
        <CardBody>
            <h4 className="header-title">Edit Snippet</h4>
            <Form onSubmit={handleSubmit(onSubmit)}>
            <Nav tabs>
                {fields?.map((i: any, index) => (
                <>
                    <NavItem>
                    <NavLink
                        className={activeTab === `${index + 1}` ? "active" : ""}
                        onClick={function noRefCheck() {
                        setActiveTab(`${index + 1}`);
                        }}
                    >
                        <NavTabsItem item={i}/>
                    </NavLink>
                    </NavItem>
                </>
                ))}
            </Nav>
            <TabContent activeTab={activeTab}>
                {fields?.map((i: any, index) => (
                <TabPane tabId={(index + 1).toString()} key={i.id}>
                    <Row>
                    <Col sm="12">
                      <FormGroup row>
                          <Label for="domain_id" sm={3}>
                              Domain
                          </Label>
                          <Col sm={9}>
                            <Controller
                              name={`snippets.${index}.domain_id`}
                              control={control}
                              render={({ field: { value } }) => (
                                <SelectDomain getValue={value} onChange={(value: any) => {
                                  setValue(`snippets.${index}.domain_id`, value.value)
                                }}
                                />
                              )}
                            />
                            {errors.domain_id && <span>This field is required</span>}
                          </Col>
                      </FormGroup>
                    </Col>

                    
                    <input type='hidden' value={params.id} name={`snippets.${index}.id`} />

                    <Col sm="12">
                      <FormGroup row>
                          <Label for="snippet_type" sm={3}>
                            Snippet Type
                          </Label>
                          <Col sm={9}>
                            <Controller
                              name={`snippets.${index}.snippet_type`}
                              control={control}
                              render={({ field: { value } }) => (
                                <SelectSnippetType getValue={value} onChange={(value: any) => {
                                  setValue(`snippets.${index}.snippet_type`, value.value)
                                }}
                                />
                              )}
                            />
                            {errors.snippet_type && <span>This field is required</span>}
                          </Col>
                      </FormGroup>
                    </Col>

                    <Col sm="12">
                      <FormGroup row>
                          <Label for="placement" sm={3}>
                            Placement
                          </Label>
                          <Col sm={9}>
                            <Controller
                              name={`snippets.${index}.placement`}
                              control={control}
                              render={({ field: { value } }) => (
                                <SelectPlacement getValue={value} onChange={(value: any) => {
                                  setValue(`snippets.${index}.placement`, value.value)
                                }}
                                />
                              )}
                            />
                            {errors.placement && <span>This field is required</span>}
                          </Col>
                      </FormGroup>
                    </Col>

                    <Col sm="12">
                        <div>
                        <FormGroup row>
                            <Label for="name" sm={3}>
                            Name
                            </Label>
                            <Col sm={9}>
                              <Controller
                                name={`snippets.${index}.name`}
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <Input {...field} type="text" id="name" />
                                )}
                              />
                            </Col>
                            {errors.name && <span>This field is required</span>}
                        </FormGroup>
                        </div>
                    </Col>

                    <Col sm="12">
                        <div>
                        <FormGroup row>
                            <Label for="content" sm={3}>
                            Content
                            </Label>
                            <Col sm={9}>
                            <Controller
                                name={`snippets.${index}.content`}
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                <Input
                                    {...field}
                                    type="textarea"
                                    id="content"
                                />
                                )}
                            />
                            </Col>
                            {errors.content && <span>This field is required</span>}
                        </FormGroup>
                        </div>
                    </Col>
                    </Row>
                </TabPane>
                ))}
            </TabContent>
            <Button color="primary" type="submit">
                Submit
            </Button>
            </Form>
        </CardBody>
        </Card>
    )}
    </>
  );
}

export default EditSnippet