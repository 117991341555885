import { queryToString } from "../utils/functions";
import { IApiParams } from "../utils/models";
import { Cookies } from "react-cookie";
import { getDomainId } from "./utils";
// export const API_URL ='https://stg.fast.pickvisa.com/api/v1'
export const API_URL = process.env.REACT_APP_BASE_URL
export const ACE_URL = process.env.REACT_APP_ACE_BASE_URL
const Endpoints = {
  languages: {
    base: `${API_URL}/languages`,
    getAll(params: IApiParams<null, null, null>): string {
      const { query } = params;
      return this.base + queryToString(query);
    },
    getSingle(id: string | string[] | undefined): string {
      return this.base + "/" + id;
    },
  },
  domain: {
    base: `${API_URL}/dashboard/domain`,
    getAll(params: IApiParams<null, null, null>): string {
      const { query } = params;
      return this.base + queryToString(query);
    },
    getConfig(id: string | string[] | undefined): string {
      return this.base + "/config/" + id;
    },
    getBase(): string {
      return this.base
    },
  },
  users: {
    base: `${API_URL}/dashboard/users/user_list`,
    getAll(params: IApiParams<null, null, null>): string {
      const { query } = params;
      return this.base + queryToString(query);
    },
    getSingle(id: string | string[] | undefined): string {
      return this.base + "/" + id;
    },
    create(): string {
      return this.base
    },
    delete(): string {
      return  `${API_URL}/dashboard/users`
    },
  },
  admins: {
    base: `${API_URL}/dashboard/users/admin_list`,
    getAll(params: IApiParams<null, null, null>): string {
      const { query } = params;
      return this.base + queryToString(query);
    },
    getSingle(id: string | string[] | undefined): string {
      return this.base + "/" + id;
    },
  },
  // seo_texts: {
  //   base: `${API_URL}/dashboard/domain/${getBaseUrl()}/content/seo_text`,
  //   getAll(params: IApiParams<null, null, null>): string {
  //     const { query } = params;
  //     return this.base + queryToString(query);
  //   },
  // },
};
export function getBaseUrl  (params: IApiParams<null, any, null>)  {
  const { query ,path} = params;
  const base = `${API_URL}/dashboard/domain/${getDomainId()}/${path}`
  return base + queryToString(query)
};
export default Endpoints;
