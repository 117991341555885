import React from 'react'
import {
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
} from 'reactstrap';
import { Toaster } from 'react-hot-toast';
import { useFieldArray, useForm,Controller } from "react-hook-form";
import {
    useParams
  } from "react-router-dom";
import { useMessagesQuery } from 'services/contact';
interface IProps {

}

const ViewMessageDetail: React.FC<IProps>= () =>{
  const params = useParams();

  const { data: Message,isLoading } = useMessagesQuery.useGetSingle(params.id)

  const { control, handleSubmit } = useForm();
  const { fields, append } = useFieldArray({
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "message", // unique name for your Field Array
  });
  React.useEffect(() => {
    if (Message) {
        append(Message);
    }
  }, [Message]);

  function onSubmit(){
    console.log('test')
  }

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <Card className="mt-3">
          <Toaster />
          <CardBody>
            <h4 className="header-title">Message</h4>
            <Form onSubmit={handleSubmit(onSubmit)}>
              {fields?.map((i: any, index) => (
                  <Row key={index}>
                    <Col sm="12">
                      <div>
                        <FormGroup row>
                          <Label for="email" sm={3}>
                            Email
                          </Label>
                          <Col sm={9}>
                            <Controller
                              name={`message.${index}.email`}
                              control={control}
                              render={({ field }) => (
                                <Input {...field} type="text" id="email" disabled />
                              )}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="full_name" sm={3}>
                            Full name
                          </Label>
                          <Col sm={9}>
                            <Controller
                              name={`message.${index}.full_name`}
                              control={control}
                              render={({ field }) => (
                                <Input {...field} type="text" id="full_name" disabled/>
                              )}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="message" sm={3}>
                            Message
                          </Label>
                          <Col sm={9}>
                            <Controller
                              name={`message.${index}.message`}
                              control={control}
                              render={({ field }) => (
                                <Input {...field} type="text" id="message" disabled/>
                              )}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="type" sm={3}>
                            Message Type
                          </Label>
                          <Col sm={9}>
                            <Controller
                              name={`message.${index}.type.name`}
                              control={control}
                              render={({ field }) => (
                                <Input {...field} type="text" id="type" disabled/>
                              )}
                            />
                          </Col>
                        </FormGroup>
                      </div>
                    </Col>
                  </Row>
              ))}
              <Button color="danger" type="submit">
                Delete
              </Button>
            </Form>
          </CardBody>
        </Card>
      )}
    </>
  );
}

export default ViewMessageDetail