import { AxiosResponse } from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { TeamMembers, TeamMembersData } from "../../shared/models/FormEntity";
import { APIClient } from "../../utils/api/client";
import { IError } from "../../utils/models";
import { getBaseUrl } from "../endpoints";

// export interface IGetAdsQueryParams {
//   isCredit?: boolean;
//   isExchange?: boolean;
// }
export interface IGetTeamMemberConfig {
}


const TeamMemberServices = {
  getAll: (data?: any): Promise<TeamMembers> => {
    return APIClient.get(getBaseUrl(data));
  },
  getSingle: (data: string | string[] | undefined): Promise<TeamMembersData[]> => {
    return APIClient.get(getBaseUrl({path:`company/team/${data}`}));
  },
  create: (data?: any): Promise<AxiosResponse<TeamMembers>> => {
    return APIClient.post( getBaseUrl({path:'company/team'}), data);
  },
  update: (data?: any): Promise<AxiosResponse<TeamMembersData[]>> => {
    return APIClient.put( getBaseUrl({path:'company/team'}), data);
  },
  delete: (data?: any): Promise<AxiosResponse<{}>> => {
    return APIClient.delete(getBaseUrl({path:'company/team'}),{ data:data });
  },
};

export const useTeamMemberQuery = {
  useGetAll: (
    data?: any  ): UseQueryResult<TeamMembers, IError> => {
    return useQuery<TeamMembers, IError>(
      ["get-team-members", data],
      async (): Promise<TeamMembers> => {
        const res = await TeamMemberServices.getAll(data);
        return res;
      }
    );
  },
  useGetSingle: (
      identifier: string | string[] | undefined    ): UseQueryResult<TeamMembersData[]> => {
      return useQuery<TeamMembersData[]>(
        ["get-team-member", identifier],
        async (): Promise<TeamMembersData[]> => {
          const res = await TeamMemberServices.getSingle(identifier);
          return res;
        },
        {
          cacheTime:0
        }
      );
    },
  useCreate: (onSuccess:() => void): UseMutationResult<TeamMembers, IError> => {
    return useMutation<TeamMembers, IError, unknown>(
      async (data:any): Promise<TeamMembers> => {
        const res = await TeamMemberServices.create(data);
        return res?.data;
      },
      {
        onSuccess:onSuccess
      }
    );
  },
  useUpdate: (onSuccess:() => void): UseMutationResult<TeamMembersData[], IError> => {
    return useMutation<TeamMembersData[], IError, unknown>(
      async (data:any): Promise<TeamMembersData[]> => {
        const res = await TeamMemberServices.update(data);
        return res?.data;
      },
      {
        onSuccess:onSuccess
      }
    );
  },
  useDelete: (onSuccess:(data:any) => void): UseMutationResult<{}, IError> => {
    return useMutation<{}, IError, unknown>(
      async (data:any): Promise<{}> => {
        const res = await TeamMemberServices.delete(data);
        return res;
      },
      {
        onSuccess:onSuccess
      },
      
    );
  },
};

export default useTeamMemberQuery;
