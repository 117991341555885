// @flow
import { getLoggedInUser } from '../../services/auth/authUtils';
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILED,
    LOGOUT_USER
} from './constants';


const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false,
};


const Auth = (state = INIT_STATE, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, loading: true };
        case LOGIN_USER_SUCCESS:
            return { ...state, user: action.payload, loading: false, error: null };
        case LOGIN_USER_FAILED:
            return { ...state, error: action.payload, loading: false };
        case LOGOUT_USER:
                return { ...state, user: null };
        default:
            return { ...state };
    }
};

export default Auth;
