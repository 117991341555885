import * as React from "react";
import useDomainsQuery from "../../../../services/domains";
import { Modify } from "../../../../utils/functions";
import Select from 'react-select';
import { StateManagerProps } from "react-select/dist/declarations/src/stateManager";

export interface ISelectDomainProps extends StateManagerProps {
  getValue?: any
}
export interface DomainOptionData{
  url:string,
  id?:number
}

const SelectDomain: React.FunctionComponent<ISelectDomainProps> = (props) => {
  const {getValue} = props;

  const {
    data: domains,
    isLoading,
  } = useDomainsQuery.useGetAll();
  const getLangOptions = (domains:DomainOptionData[] | undefined) => {
    return domains?.map(domain => {
         return {
           label: domain.url,
           value: domain.id
         }
    })
}
  return <Select
        className="basic-single"
        classNamePrefix="select"
        isLoading={isLoading}
        name="color"
        placeholder="Select Domain (Required)"
        value={getLangOptions(domains?.objects)?.find(c => c.value == getValue)}
        options={Modify.selectOptionsDomain(domains?.objects|| [])}
        {...props}
        />
};

export default SelectDomain;
