import axios from "axios";
import { API_URL } from "../endpoints";

/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
 const fetchJSON = (url:string, options = {}) => {
    return fetch(url, options)
        .then((response:any) => {
            if (!(response?.status  === 200)) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => {
            throw error;
        });
};
const fetchJSON2 = (url:string, options:any) => {
      
      var config = {
        method: 'post',
        url: API_URL+url,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : options.body
      };
    return axios(config)
        .then(function (response:any) {
            return response.data
        })
        .catch(function (error:any) {
            throw error
        });
};

export { fetchJSON,fetchJSON2 };
