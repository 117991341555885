import BootstrapTable, { BootstrapTableProps } from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
// ...
interface IProps  extends BootstrapTableProps {
  page:any,
  sizePerPage:any,
  totalSize:any
}

const RemotePagination: React.FC<IProps> = ({ data,columns, page, sizePerPage, onTableChange, totalSize }) => {
 return(
      <div>
      <PaginationProvider
        pagination={
          paginationFactory({
            custom: true,
            page,
            sizePerPage,
            totalSize
          })
        }
      >
        {
          ({
            paginationProps,
            paginationTableProps
          }) => (
            <div>
              <div>
                <p>Current Page: { paginationProps.page }</p>
              </div>
              <BootstrapTable
                { ...paginationTableProps }
                remote
                keyField='id'
                data={ data }
                columns={ columns }
                onTableChange={ onTableChange }
                bordered={false}
                striped
              />
              <div>
                <PaginationListStandalone
                  { ...paginationProps }
                />
              </div>
            </div>
          )
        }
      </PaginationProvider>
    </div>
 )
}
export default RemotePagination