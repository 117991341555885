import { AxiosResponse } from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { queryToString } from "utils/functions";
import { LangListEntity } from "../../shared/models/FormEntity";
import { APIClient } from "../../utils/api/client";
import { IError } from "../../utils/models";
import { getBaseUrl } from "../endpoints";

export interface IGetLanguageQueryParams {
}

const LanguagesServices = {
  getAll: (data?: IGetLanguageQueryParams): Promise<LangListEntity> => {
    return APIClient.get(getBaseUrl({path:`content/languages${queryToString(data)}`}));
  },
//   getSingle: (data: string | string[] | undefined): Promise<SeoTexts[]> => {
//     return APIClient.get('/api/v1/content/languages?domain=1');
//   },
  create: (data?: any): Promise<AxiosResponse<LangListEntity>> => {
    return APIClient.post(getBaseUrl({path:'content/languages'}), { ...data });
  },
  delete: (data?: any): Promise<AxiosResponse<{}>> => {
    return APIClient.delete(getBaseUrl({path:'content/languages'}),{ data:data });
  },
};

export const useLanguagesQuery = {
  useGetAll: (
    data?: IGetLanguageQueryParams,
  ): UseQueryResult<LangListEntity, IError> => {
    return useQuery<LangListEntity, IError>(
      ["languages", data],
      async (): Promise<LangListEntity> => {
        const res = await LanguagesServices.getAll(data);  
        return res 
      },
    );
  },
//   useGetSingle: (
//       identifier: string | string[] | undefined,
//       meta?: IGetAdsConfig
//     ): UseQueryResult<SeoTexts[]> => {
//       return useQuery<SeoTexts[]>(
//         ["get-seo-texts", identifier],
//         async (): Promise<SeoTexts[]> => {
//           const res = await SeoTextServices.getSingle(identifier);
//           return res;
//         }
//       );
//     },
  useCreate: (onSuccess:() => void): UseMutationResult<LangListEntity, IError> => {
    return useMutation<LangListEntity, IError, unknown>(
      async (data:any): Promise<LangListEntity> => {
        const res = await LanguagesServices.create(data);
        return res?.data;
      },
      {
        onSuccess:onSuccess
      }
    );
  },
  useDelete: (onSuccess:(data:any) => void): UseMutationResult<{}, IError> => {
    return useMutation<{}, IError, unknown>(
      async (data:any): Promise<{}> => {
        const res = await LanguagesServices.delete(data);
        return res;
      },
      {
        onSuccess:onSuccess
      },
      
    );
  },
};

export default useLanguagesQuery;
