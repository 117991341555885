import React from 'react';
// import { Redirect } from 'react-router-dom';

import * as FeatherIcon from 'react-feather';
import Users from 'pages/Users';
import viewUser from 'pages/Users/viewUser';
import CreateUser from 'pages/Users/createUser';
import Translations from 'pages/Translations';
import CreateTranslation from 'pages/Translations/CreateTranslation';
import EditTranslation from 'pages/Translations/EditTranslation'
import CreateSeoText from 'pages/SeoTexts/CreateSeoText/index'
import EditSeoTexts from 'pages/SeoTexts/EditSeoTexts/index'
import StaticContents from 'pages/StaticContents';
import EditStaticContents from 'pages/StaticContents/EditStaticContents';
import CreateStaticContents from 'pages/StaticContents/CreateStaticContents';
import Domains from 'pages/Domains';
import CreateDomain from 'pages/Domains/CreateDomain/index';
import CreateLanguage from 'pages/Languages/CreateLanguage';
import Languages from 'pages/Languages';
import EditTeamMembers from 'pages/TeamMembers/EditTeamMembers';
import CreateTeamMembers from 'pages/TeamMembers/CreateTeamMembers';
import TeamMembers from 'pages/TeamMembers';
import CreateOffice from 'pages/Offices/CreateOffice';
import Offices from 'pages/Offices';
import EditOffice from 'pages/Offices/EditOffice';
import PrivateRoute from './PrivateRoute';
import Social from 'pages/Social';
import CreateSocial from 'pages/Social/CreateSocial';
import EditSocial from 'pages/Social/EditSocial';
import PopularDestinations from 'pages/PopularDestinations';
import CreatePopularDestinations from 'pages/PopularDestinations/CreatePopularDestinations';
import EditPopularDestinations from 'pages/PopularDestinations/EditPopularDestinations';
import MessageTypes from 'pages/Contact/MessageTypes';
import CreateMessageTypes from 'pages/Contact/MessageTypes/CreateMessageTypes';
import EditMessageTypes from 'pages/Contact/MessageTypes/EditMessageTypes';
import Messages from 'pages/Contact/Messages';
import ViewMessageDetail from 'pages/Contact/Messages/ViewMessageDetail';
import Categories from 'pages/Faq/Categories';
import CreateFaqCategory from 'pages/Faq/Categories/CreateFaqCategory';
import EditFaqCategory from 'pages/Faq/Categories/EditFaqCategory';
import Questions from 'pages/Faq/Questions';
import EditFaqQuestion from 'pages/Faq/Questions/EditFaqQuestion';
import CreateFaqQuestion from 'pages/Faq/Questions/CreateFaqQuestion';
// import Countries from 'pages/Countries';
// import EditCountry from 'pages/Countries/EditCountry';
// import CreateCountry from 'pages/Countries/CreateCountry';
import Logout from 'pages/auth/Logout';
import Snippets from 'pages/Snippets';
import CreateSnippet from 'pages/Snippets/CreateSnippet';
import EditSnippet from 'pages/Snippets/EditSnippet'





// pages
const SeoTexts = React.lazy(() => import('pages/SeoTexts/index'));
const Admins = React.lazy(() => import('pages/Admins/index'));
const CreateAdmin = React.lazy(() => import('pages/Admins/CreateAdmin/index'));
const Login = React.lazy(() => import('pages/auth/login/index'));



const SeoTextsRoutes = {
    path: '/wadmin/seo-texts',
    name: 'SeoTexts',
    component: SeoTexts,
    icon: FeatherIcon.TrendingUp,
    roles: ['Admin'],
    route: PrivateRoute
};
const SingleSeoTextRoutes = {
    path: '/wadmin/seo-texts/:id/edit',
    name: 'SingleSeoTexts',
    component: EditSeoTexts,
    roles: ['Admin'],
    route: PrivateRoute
};
const CreateSeoTextRoutes = {
    path: '/wadmin/seo-texts/create',
    name: 'CreateAdmin',
    component: CreateSeoText,
    roles: ['Admin'],
    route: PrivateRoute
};
const AdminsRoutes = {
    path: '/wadmin/admins',
    name: 'Admins',
    component: Admins,
    icon: FeatherIcon.UserCheck,
    roles: ['Admin'],
    route: PrivateRoute
};

const CreateAdminRoutes = {
    path: '/wadmin/admins/create',
    name: 'CreateAdmin',
    component: CreateAdmin,
    roles: ['Admin'],
    route: PrivateRoute
};
const UsersRoutes = {
    path: '/wadmin/users',
    name: 'Users',
    component: Users,
    icon: FeatherIcon.Users,
    roles: ['Admin'],
    route: PrivateRoute
};
const ViewUserRoutes = {
    path: '/wadmin/users/:id',
    name: 'Users view',
    component: viewUser,
    roles: ['Admin'],
    route: PrivateRoute
};
const SingleUserRoutes = {
    path: '/wadmin/users/create',
    name: 'SingleUser',
    component: CreateUser,
    roles: ['Admin'],
    route: PrivateRoute
};
const CreateUserRoutes = {
    path: '/wadmin/users/create',
    name: 'CreateUser',
    component: CreateUser,
    roles: ['Admin'],
    route: PrivateRoute
};
const TranslationsRoutes = {
    path: '/wadmin/template-variable',
    name: 'Translations',
    component: Translations,
    icon: FeatherIcon.Info,
    roles: ['Admin'],
    route: PrivateRoute
};
const SingleTranslationRoutes = {
    path: '/wadmin/template-variable/:id/edit',
    name: 'SingleTranslation',
    component: EditTranslation,
    roles: ['Admin'],
    route: PrivateRoute
};
const CreateTranslationRoutes = {
    path: '/wadmin/template-variable/create',
    name: 'CreateTranslation',
    component: CreateTranslation,
    roles: ['Admin'],
    route: PrivateRoute
};
const SnippetRoutes = {
    path: '/wadmin/snippets',
    name: 'Snippets',
    component: Snippets,
    route: PrivateRoute,
    roles: ['Admin'],
    icon: FeatherIcon.Code
};
const CreateSnippetRoutes = {
    path: '/wadmin/snippets/create',
    name: 'CreateSnippet',
    component: CreateSnippet,
    roles: ['Admin'],
    route: PrivateRoute
};
const EditSnippetRoutes = {
    path: '/wadmin/snippets/:id/edit',
    name: 'EditSnippet',
    component: EditSnippet,
    roles: ['Admin'],
    route: PrivateRoute
};
const StaticContentsRoutes = {
    path: '/wadmin/static-contents',
    name: 'Static Contents',
    component: StaticContents,
    icon: FeatherIcon.Layout,
    roles: ['Admin'],
    route: PrivateRoute
};
const SingleStaticContentRoutes = {
    path: '/wadmin/static-contents/:id/edit',
    name: 'SingleTranslation',
    component: EditStaticContents,
    roles: ['Admin'],
    route: PrivateRoute
};
const CreateStaticContentRoutes = {
    path: '/wadmin/static-contents/create',
    name: 'CreateTranslation',
    component: CreateStaticContents,
    roles: ['Admin'],
    route: PrivateRoute
};
const LoginRoutes = {
    path: '/wadmin/login',
    name: 'Login',
    component: Login,
};
const LogoutRoutes = {
    path: '/wadmin/logout',
    name: 'Logout',
    component: Logout,
    icon: FeatherIcon.UserCheck,
};

const CreateDomainRoutes = {
    path: '/wadmin/domains/create',
    name: 'CreateDomain',
    component: CreateDomain,
    roles: ['Admin'],
    route: PrivateRoute
};
const DomainRoutes = {
    path: '/wadmin/domains',
    name: 'Domains',
    component: Domains,
    icon: FeatherIcon.MousePointer,
    roles: ['Admin'],
    route: PrivateRoute
};

const CreateLanguageRoutes = {
    path: '/wadmin/languages/create',
    name: 'CreateLanguage',
    component: CreateLanguage,
    roles: ['Admin'],
    route: PrivateRoute
};
const LanguageRoutes = {
    path: '/wadmin/languages',
    name: 'Languages',
    component: Languages,
    icon: FeatherIcon.Globe,
    roles: ['Admin'],
    route: PrivateRoute
};
const SingleTeamMembersRoutes = {
    path: '/wadmin/team-members/:id/edit',
    name: 'EditTeamMembers',
    component: EditTeamMembers,
    roles: ['Admin'],
    route: PrivateRoute
};
const CreateTeamMembersRoutes = {
    path: '/wadmin/team-members/create',
    name: 'CreateTeamMembers',
    component: CreateTeamMembers,
    roles: ['Admin'],
    route: PrivateRoute
};
const TeamMembersRoutes = {
    path: '/wadmin/team-members',
    name: 'Team Members',
    component: TeamMembers,
    icon: FeatherIcon.Heart,
    roles: ['Admin'],
    route: PrivateRoute
};
const SingleEditOfficeRoutes = {
    path: '/wadmin/offices/:id/edit',
    name: 'EditOffice',
    component: EditOffice,
    roles: ['Admin'],
    route: PrivateRoute
};
const CreateOfficeRoutes = {
    path: '/wadmin/offices/create',
    name: 'CreateOffice',
    component: CreateOffice,
    roles: ['Admin'],
    route: PrivateRoute
};
const OfficesRoutes = {
    path: '/wadmin/offices',
    name: 'Offices',
    component: Offices,
    icon: FeatherIcon.MapPin,
    roles: ['Admin'],
    route: PrivateRoute
};
const SingleEditSocial = {
    path: '/wadmin/social/:id/edit',
    name: 'EditSocial',
    component: EditSocial,
    roles: ['Admin'],
    route: PrivateRoute
};
const CreateSocialRoutes = {
    path: '/wadmin/social/create',
    name: 'CreateSocial',
    component: CreateSocial,
    roles: ['Admin'],
    route: PrivateRoute
};
const SocialRoutes = {
    path: '/wadmin/social',
    name: 'Social',
    component: Social,
    icon: FeatherIcon.Facebook,
    roles: ['Admin'],
    route: PrivateRoute
};
const SingleEditPopularDestinations = {
    path: '/wadmin/popular-destinations/:id/edit',
    name: 'EditPopularDestinations',
    component: EditPopularDestinations,
    roles: ['Admin'],
    route: PrivateRoute
};
const CreatePopularDestinationsRoutes = {
    path: '/wadmin/popular-destinations/create',
    name: 'CreatePopularDestinations',
    component: CreatePopularDestinations,
    roles: ['Admin'],
    route: PrivateRoute
};
const PopularDestinationsRoutes = {
    path: '/wadmin/popular-destinations',
    name: 'Popular Destinations',
    component: PopularDestinations,
    icon: FeatherIcon.Anchor,
    roles: ['Admin'],
    route: PrivateRoute
};
const SingleEditMessageTypesRoutes = {
    path: '/wadmin/contact-me-message-types/:id/edit',
    name: 'Edit MessageTypes',
    component: EditMessageTypes,
    roles: ['Admin'],
    route: PrivateRoute
};
const ContactRoutes = {
    path: '/wadmin/contact-me-message-types',
    name: 'Contact',
    component: MessageTypes,
    roles: ['Admin'],
    route: PrivateRoute,
    children: [
        {
            path: '/wadmin/contact-me-message-types',
            name: 'Message Types',
            component: MessageTypes,
            route: PrivateRoute,
            roles: ['Admin'],
        },
        {
            path: '/wadmin/contact-me-messages',
            name: 'Messages',
            component: Messages,
            roles: ['Admin'],
            route: PrivateRoute
        }
        
    ],
};
const CreateMessageTypesRoutes = {
    path: '/wadmin/contact-me-message-types/create',
    name: 'Create Message Types',
    component: CreateMessageTypes,
    roles: ['Admin'],
    route: PrivateRoute
};
const ViewMessageDetailRoutes = {
    path: '/wadmin/contact-me-messages/:id',
    name: 'View Messages',
    component: ViewMessageDetail,
    roles: ['Admin'],
    route: PrivateRoute
};
const FaqRoutes = {
    path: '/wadmin/faq-categories',
    name: 'Faq',
    children: [
        {
            path: '/wadmin/faq-categories',
            name: 'Categories',
            component: Categories,
            route: PrivateRoute,
            roles: ['Admin'],
        },
        {
            path: '/wadmin/faq-questions',
            name: 'Questions',
            component: Questions,
            roles: ['Admin'],
            route: PrivateRoute
        }
        
    ],
};
const SingleEditFaqCategoryRoutes = {
    path: '/wadmin/faq-categories/:id/edit',
    name: 'Edit Faq Category',
    component: EditFaqCategory,
    roles: ['Admin'],
    route: PrivateRoute
};
const CreateFaqCategoryRoutes = {
    path: '/wadmin/faq-categories/create',
    name: 'Create Faq Category',
    component: CreateFaqCategory,
    roles: ['Admin'],
    route: PrivateRoute
};

const SingleEditFaqQuestionRoutes = {
    path: '/wadmin/faq-questions/:id/edit',
    name: 'Edit Faq Question',
    component: EditFaqQuestion,
    roles: ['Admin'],
    route: PrivateRoute
};
const CreateFaqQuestionRoutes = {
    path: '/wadmin/faq-questions/create',
    name: 'Create Faq Question',
    component: CreateFaqQuestion,
    roles: ['Admin'],
    route: PrivateRoute
};
// const CountriesRoutes = {
//     path: '/wadmin/countries',
//     name: 'Countries',
//     component: Countries,
//     icon: FeatherIcon.Flag,
//     roles: ['Admin'],
//     route: PrivateRoute
// };
// const SingleCountryRoutes = {
//     path: '/wadmin/countries/:id/edit',
//     name: 'Single Country',
//     component: EditCountry,
//     roles: ['Admin'],
//     route: PrivateRoute
// };
// const CreateCountryRoutes = {
//     path: '/wadmin/countries/create',
//     name: 'CreateTranslation',
//     component: CreateCountry,
//     roles: ['Admin'],
//     route: PrivateRoute
// };
const flattenRoutes = (routes:any) => {
    let flatRoutes:any = [];

    routes = routes || [];
    routes.forEach((item:any) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};


// All routes
const allRoutes = [
    // rootRoute,
    SeoTextsRoutes,
    SingleSeoTextRoutes,
    CreateSeoTextRoutes,
    AdminsRoutes,
    CreateAdminRoutes,
    LoginRoutes,
    UsersRoutes,
    SingleUserRoutes,
    CreateUserRoutes,
    SingleEditOfficeRoutes,
    CreateOfficeRoutes,
    OfficesRoutes,
    TranslationsRoutes,
    SingleTranslationRoutes,
    CreateTranslationRoutes,
    SnippetRoutes,
    CreateSnippetRoutes,
    EditSnippetRoutes,
    StaticContentsRoutes,
    SingleStaticContentRoutes,
    CreateStaticContentRoutes,
    CreateDomainRoutes,
    DomainRoutes,
    CreateLanguageRoutes,
    LanguageRoutes,
    SingleTeamMembersRoutes,
    CreateTeamMembersRoutes,
    TeamMembersRoutes,
    SingleEditSocial,
    CreateSocialRoutes,
    SocialRoutes,
    SingleEditPopularDestinations,
    CreatePopularDestinationsRoutes,
    PopularDestinationsRoutes,
    ContactRoutes,
    CreateMessageTypesRoutes,
    SingleEditMessageTypesRoutes,
    ViewMessageDetailRoutes,
    FaqRoutes,
    CreateFaqCategoryRoutes,
    SingleEditFaqCategoryRoutes,
    SingleEditFaqQuestionRoutes,
    CreateFaqQuestionRoutes,
    ViewUserRoutes,
    // CountriesRoutes,
    // SingleCountryRoutes,
    // CreateCountryRoutes,
    LogoutRoutes
];

const authProtectedRoutes = [AdminsRoutes,UsersRoutes,DomainRoutes,LanguageRoutes,SeoTextsRoutes,/*CountriesRoutes,*/TranslationsRoutes,SnippetRoutes,StaticContentsRoutes,TeamMembersRoutes,OfficesRoutes,SocialRoutes,PopularDestinationsRoutes,ContactRoutes,FaqRoutes];

const allFlattenRoutes = flattenRoutes(allRoutes);
export { allRoutes,authProtectedRoutes,allFlattenRoutes };
