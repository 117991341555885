import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Card,
    CardBody,
    Button,
} from 'reactstrap';
import useLanguagesQuery from '../../services/languages';
import RemotePagination from '../../shared/components/Pagination';
import * as FeatherIcon from 'react-feather';
import toast, { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';

interface IProps {

}

const CreateLanguage: React.FC<IProps>= (props) =>  {
    let navigate = useNavigate();
    const [page,setPage] = React.useState(0)
    const onDeleteSubmit = () => {
        toast('Language has been successfully deleted.', {
            duration: 2000,
            position: 'top-center',
            icon: '👍🏻',
          });
    }
    function DeleteLanguage() {
        return (
           <Button color="outline-danger"> <FeatherIcon.Trash className="icon-xs icon-dual"></FeatherIcon.Trash></Button>
        );
    }

    const { search_text } = useSelector((state:any) => state.SearchReducer);

    const {
        data: Languages,
    } = useLanguagesQuery.useGetAll(
        {
            'limit':10,
            'prev':page * 10,
            ...(search_text ? {"search_key": search_text} : "")
        }
    );
    
    const {
        mutate: mutateLanguageDelete,
        error
    } = useLanguagesQuery.useDelete(() => onDeleteSubmit());
    React.useEffect(() => {
        if (error) {
            toast.error(error.error as string)
        }
    }, [error])
    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
        },
        {
            dataField: 'short_name',
            text: 'Short name',
            events: {
                onClick: (e:any, column:any, columnIndex:any, row:any, rowIndex:any) => {
                  navigate(`${rowIndex}/edit`, { replace: false });
                }
            }
        },
        {
            dataField: 'long_name',
            text: 'Long name',
        },
        {
            dataField: 'is_active',
            text: 'Is active',
            sort: false,
        },
        {
            dataField: 'delete',
            text: 'Delete',
            formatter: DeleteLanguage,
            events: {
                onClick: (e:any, column:any, columnIndex:any, row:any, rowIndex:any) => {
                    if (window.confirm("Do you really want to delete this language 😳?") === true) {
                        mutateLanguageDelete(
                            {
                                'id':row.id
                            }
                        )
                      } 
                }
            }
        }
    ];
    const  handleTableChange = (type:any,{page,sizePerPage}:any) => {
        setPage(page-1)
    }
    return (
        <>
            <Card className='mt-3'>
                <Toaster />
                <CardBody>
                    <Link to="/wadmin/languages/create">
                        <Button color="primary" type="submit">
                            Create Language
                        </Button>
                    </Link>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <RemotePagination
                            data={Languages?.objects || []}
                            page={page+1}
                            sizePerPage={Languages?.meta.limit}
                            totalSize={ (Languages?.meta.limit as number) * (Languages?.meta.max_page as number)} keyField={''} columns={columns}
                            onTableChange={handleTableChange}               
                        />
                </CardBody>
            </Card>
       </>
      
    )
} 

export default CreateLanguage;
