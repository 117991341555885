import * as React from 'react';
import {
    Card,
    CardBody,
    Button,
} from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import RemotePagination from 'shared/components/Pagination';
import useMessageTypesQuery from 'services/contact';
import * as FeatherIcon from 'react-feather';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
interface IProps {

}

const MessageTypes: React.FC<IProps>= (props) =>  {
    let navigate = useNavigate();
    function UpdateMessageTypes(cell:any, row:any) {
        return (
           <Button color="outline-info"> <FeatherIcon.Edit className="icon-xs icon-dual"></FeatherIcon.Edit></Button>
        );
    }
    const onDeleteSubmit = () => {
      toast('Message type has been successfully deleted.', {
          duration: 2000,
          position: 'top-center',
          icon: '👍🏻',
        });
    }
    function DeleteMessageType() {
      return (
          <Button color="outline-danger"> <FeatherIcon.Trash className="icon-xs icon-dual"></FeatherIcon.Trash></Button>
      );
    }

    const {
      mutate: mutateStaticDelete,
    } = useMessageTypesQuery.useDelete(() => onDeleteSubmit());

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
        },
        {
            dataField: 'priority',
            text: 'Priority',
        },
        {
            dataField: 'name',
            text: 'Name',
        },
        {
            dataField: 'unique_identifier',
            text: 'Edit',
            formatter: UpdateMessageTypes,
            events: {
                onClick: (e:any, column:any, columnIndex:any, row:any, rowIndex:any) => {
                  navigate(`${row.unique_identifier}/edit`, { replace: false });
                //   alert('Click on Product ID field');
                }
            }
        },
        {
          dataField: 'delete',
          text: 'Delete',
          formatter: DeleteMessageType,
          events: {
              onClick: (e:any, column:any, columnIndex:any, row:any, rowIndex:any) => {
                  if (window.confirm("Do you really want to delete this message type  😳?") === true) {
                    mutateStaticDelete(
                          {
                              'id':row.id
                          }
                      )
                    } else {
                     
                    }
              }
          }
      }
    ];
    const { language } = useSelector((state:any) => state.LangReducer);
    const { search_text } = useSelector((state:any) => state.SearchReducer);

    const [page,setPage] = React.useState(0)
    const {
        data: MessageTypes,
    } = useMessageTypesQuery.useGetAll({
        query:{
            'language_id':language,
            'limit':10,
            'prev':page * 10,
            ...(search_text ? {"search_key": search_text} : "")
        }
    });
    const  handleTableChange = (type:any,{page,sizePerPage}:any) => {
        setPage(page-1)
    }

    return (
        <>
        <Card className="mt-3">
          <CardBody>
            <Link to="/wadmin/contact-me-message-types/create">
              <Button color="primary" type="submit">
                Create Message types
              </Button>
            </Link>
          </CardBody>
        </Card>
    {MessageTypes?.objects && MessageTypes?.objects.length ? (
      <>
        <Card>
          <CardBody>
            <RemotePagination
              data={MessageTypes?.objects || []}
              page={page + 1}
              sizePerPage={MessageTypes?.meta.limit}
              totalSize={
                (MessageTypes?.meta.limit as number) *
                (MessageTypes?.meta.max_page as number)
              }
              keyField={""}
              columns={columns}
              onTableChange={handleTableChange} // onTableChange={ this.handleTableChange }
            />
          </CardBody>
        </Card>
      </>
    ) : (
      <div className='d-flex align-items-center justify-content-center flex-column'>
        <h4>No content</h4>
        <img src="https://cdn-icons-png.flaticon.com/512/7303/7303353.png" alt="" width='50px' />
      </div>
    )}
  </>
      
    )
} 

export default MessageTypes;
