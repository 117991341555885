import * as React from "react";
import Select from 'react-select';
import { StateManagerProps } from "react-select/dist/declarations/src/stateManager";

export interface ISelectSnippetTypeProps extends StateManagerProps {
  getValue?: any
}
export interface SnippetTypeOptionData{ value: string }

const SelectSnippetType: React.FunctionComponent<ISelectSnippetTypeProps> = (props) => {
  const {getValue} = props;

  const getSnippetTypeOptions = (snippet_types: SnippetTypeOptionData[] | undefined) => {
    return snippet_types?.map(snippet_type => {
         return {
           label: snippet_type.value,
           value: snippet_type.value
         }
    })
}

const options = [
    { label: "Not Selected", value: "Not Selected" },
    { label: "Script", value: "Script" },
    { label: "Style", value: "Style" },
    { label: "No Script", value: "No Script" }
]

  return <Select
        className="basic-single"
        classNamePrefix="select"
        // isLoading={isLoading}
        name="color"
        placeholder="Select Snippet Type (Required)"
        options={options}
        value={getSnippetTypeOptions(options)?.find(c => c.value == getValue) }
        {...props}
        />
};

export default SelectSnippetType;
