import { Component, Suspense } from 'react';
import { BrowserRouter, Routes,Route, Navigate } from 'react-router-dom';
import Loadable from 'react-loadable';

// import { isUserAuthenticated } from '../helpers/authUtils';
import { allFlattenRoutes as routes } from './index';
import Loader from './Loader';
import Login from '../pages/auth/login';
import { isUserAuthenticated } from '../services/auth/authUtils';
// Lazy loading and code splitting -
// Derieved idea from https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52


// All layouts/containers
// const AuthLayout = Loadable({
//     loader: () => import('../layouts/Auth'),
//     render(loaded, props) {
//         let Component = loaded.default;
//         return <Component {...props} />;
//     },
//     loading,
// });

const HorizontalLayout = Loadable({
    loader: () => import('../layouts/Horizontal'),
    render(loaded, props) {
        let Component = loaded.default;
        return <Component {...props} />;
    },
    loading: Loader,
});

class IRoutes extends Component {
    getLayout = () => {
        if (isUserAuthenticated()) return HorizontalLayout;
        else return Login;
    }


    render() {
        const Layout = this.getLayout();
        const EpisodeWrapper = ({component:Component}:any) => {
            return isUserAuthenticated() ? <Component /> : <Navigate to="/wadmin/login"/>;
        }
        // rendering the router with layout
        return  <BrowserRouter>
        <Suspense fallback={<Loader isLoading={false} pastDelay={false} timedOut={false} error={undefined} retry={function (): void {
                throw new Error('Function not implemented.');
            } } />}>
        <Layout {...this.props}>
            <Routes>
                {routes.map((route:any, index:any) => {
                    return (
                        !route.children ?
                        <Route
                            //  {...rest}
                            key={index}
                            path={route.path}
                            element={<EpisodeWrapper component={route.component} />}
                        />
                                //  <route.route
                                //     // key={index}
                                //     // index={index}
                                //     // path={route.path}
                                //     // element={route.component}
                                //  ></route.route>
                            : null
                    );
                })}
            </Routes>
        </Layout>
        </Suspense>
    </BrowserRouter>
    }
}

export default IRoutes
