
export interface IOption {
    value?: number | string;
    label?: string;
}

export const toBase64 = (file:any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve((<string>reader.result).split(',')[1]);
    reader.onerror = error => reject(error);
});
export const queryToString = (query: any): string => {
    let str: string = '?';

    for (const key in query) {
        if (Object.prototype.hasOwnProperty.call(query, key)) {
            const val = query[key];
            str += `${key}=${val}&`
        }
    }
    return str;
}
export const Modify = {
    selectOptions: (data: { id: number, long_name: string }[]): IOption[] =>
        { return data?.map(obj => ({ label: obj.long_name, value: obj.id }))},
    selectOptionsLanguage: (data: { short_name: string, long_name: string }[]): IOption[] =>
        { return data?.map(obj => ({ label: obj.long_name, value: obj.short_name }))},
    selectOptionsDomain: (data: { id?: number, url: string }[]): IOption[] =>
       { return data?.map(obj => ({ label: obj.url, value: obj.id }))},
    selectOptionsCountry: (data: { alpha_3_code: string, name: string }[]): IOption[] =>
       { return data?.map(obj => ({ label: obj.name, value: obj.alpha_3_code }))},
    selectOptionsFaqCategory: (data: {id?: number, title: string}[]): IOption[] => 
       { return data?.map(obj => ({ label: obj.title, value: obj.id }))},
    selectOptionsPlacement: (data: { name: string }[]): IOption[] =>
       { return data?.map(obj => ({ label: obj.name, value: obj.name }))},
    selectOptionsSnippetType: (data: { name: string }[]): IOption[] =>
       { return data?.map(obj => ({ label: obj.name, value: obj.name }))},
}

