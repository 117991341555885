import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import * as reactRouterDom from 'react-router-dom'

import { Container, Row, Col, Card, CardBody, Form, FormGroup,Label,
    Input,
    Button} from 'reactstrap';
import DomainsModal from 'shared/components/Modals/DomainsModal';
import { loginUser } from '../../../redux/auth/actions';

interface IProps{

}
const Login: React.FC<IProps>= (props) =>  {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const {ref:emailRef,...email} = register('email', { required: true })
    const {ref:passwordRef,...password} = register('password', { required: true })
    let navigate = reactRouterDom.useNavigate();
    const dispatch = useDispatch()
    const error =useSelector((state:any)=> state.Auth.error);
    
    const onSubmit = (data:any) => {
        dispatch(loginUser(data,navigate))
        
    };
    
    useEffect(() => {
        if (error && error !== null) {
          toast.error(error?.response?.data.message)
        }
    }, [error])
    return (
      <>
      <DomainsModal/>
        <Container  className="mt-5">
          <Toaster/>
          <Row className="justify-content-center">
            <Col xl={10}>
              <Card className="">
                <CardBody className="p-0">
                  <Row>
                    <Col md={6} className="p-5 position-relative">
                      {/* preloader */}
                      {/* {this.props.loading && <Loader />} */}

                      <div className="mx-auto mb-5">
                        <a href="/">
                          <img src={""} alt="" height="24" />
                          <h3 className="d-inline align-middle ml-1 text-logo">
                            Pickvisa
                          </h3>
                        </a>
                      </div>

                      <h6 className="h5 mb-0 mt-4">Welcome back!</h6>
                      <p className="text-muted mt-1 mb-4">
                        Enter your email address and password to access admin
                        panel.
                      </p>
                      <Form onSubmit={handleSubmit(onSubmit)}>
                        <FormGroup row>
                          <Label for="email" sm={3}>
                            Email
                          </Label>
                          <Col sm={9}>
                            <Input
                              id="email"
                              type="text"
                              {...email}
                              innerRef={emailRef}
                              placeholder="Enter email"
                            />
                            {errors.email && <span>This field is required</span>}
                          </Col>
                        </FormGroup>

                   
                        <FormGroup row>
                          <Label for="password" sm={3}>
                            Password
                          </Label>
                          <Col sm={9}>
                            <Input
                              id="language"
                              type="password"
                              {...password}
                              innerRef={passwordRef}
                              placeholder="Enter password"
                            />
                            {errors.language && (
                              <span>This field is required</span>
                            )}
                          </Col>
                        </FormGroup>

                        <Button color="primary" type="submit">
                          Submit
                        </Button>
                      </Form>
                    </Col>

                    <Col md={6} className="d-none d-md-inline-block">
                      <div className="auth-page-sidebar">
                        <div className="overlay"></div>
                        <div className="auth-user-testimonial">
                          <p className="font-size-24 font-weight-bold text-white mb-1">
                            I simply love it!
                          </p>
                          <p className="lead">
                            "It's a elegent templete. I love it very much!"
                          </p>
                          <p>- Admin User</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* <Row className="mt-3">
                            <Col className="col-12 text-center">
                                <p className="text-muted">Don't have an account? <Link to="/account/register" className="text-primary font-weight-bold ml-1">Sign Up</Link></p>
                            </Col>
                        </Row> */}
        </Container>
      </>
    );
}
export default Login