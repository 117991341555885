import { AxiosResponse } from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { Offices, OfficesData } from "../../shared/models/FormEntity";
import { APIClient } from "../../utils/api/client";
import { IError } from "../../utils/models";
import { getBaseUrl } from "../endpoints";


export interface IGetOfficesConfig {
}


const OffieceServices = {
  getAll: (data?: any): Promise<Offices> => {
    return APIClient.get(getBaseUrl(data));
  },
  getSingle: (data: string | string[] | undefined): Promise<OfficesData[]> => {
    return APIClient.get(getBaseUrl({path:`company/office/${data}`}));
  },
  create: (data?: any): Promise<AxiosResponse<Offices>> => {
    return APIClient.post( getBaseUrl({path:'company/office'}), data);
  },
  update: (data?: any): Promise<AxiosResponse<OfficesData[]>> => {
    return APIClient.put( getBaseUrl({path:'company/office'}), data);
  },
  delete: (data?: any): Promise<AxiosResponse<{}>> => {
    return APIClient.delete(getBaseUrl({path:'company/office'}),{ data:data });
  },
};

export const useOfficesQuery = {
  useGetAll: (
    data?: any  ): UseQueryResult<Offices, IError> => {
    return useQuery<Offices, IError>(
      ["get-offices", data],
      async (): Promise<Offices> => {
        const res = await OffieceServices.getAll(data);
        return res;
      }
    );
  },
  useGetSingle: (
      identifier: string | string[] | undefined    ): UseQueryResult<OfficesData[]> => {
      return useQuery<OfficesData[]>(
        ["get-office", identifier],
        async (): Promise<OfficesData[]> => {
          const res = await OffieceServices.getSingle(identifier);
          return res;
        },
        {
          cacheTime:0
        }
      );
    },
  useCreate: (onSuccess:() => void): UseMutationResult<Offices, IError> => {
    return useMutation<Offices, IError, unknown>(
      async (data:any): Promise<Offices> => {
        const res = await OffieceServices.create(data);
        return res?.data;
      },
      {
        onSuccess:onSuccess
      }
    );
  },
  useUpdate: (onSuccess:() => void): UseMutationResult<OfficesData[], IError> => {
    return useMutation<OfficesData[], IError, unknown>(
      async (data:any): Promise<OfficesData[]> => {
        const res = await OffieceServices.update(data);
        return res?.data;
      },
      {
        onSuccess:onSuccess
      }
    );
  },
  useDelete: (onSuccess:(data:any) => void): UseMutationResult<{}, IError> => {
    return useMutation<{}, IError, unknown>(
      async (data:any): Promise<{}> => {
        const res = await OffieceServices.delete(data);
        return res;
      },
      {
        onSuccess:onSuccess
      },
      
    );
  },
};

export default useOfficesQuery;
