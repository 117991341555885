import * as React from 'react';
import { CKEditor  } from '@ckeditor/ckeditor5-react';
import  ClassicEditor  from '@ckeditor/ckeditor5-build-classic';
import {
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
} from 'reactstrap';
import { useForm } from "react-hook-form";
import toast, { Toaster } from 'react-hot-toast';
import SelectFaqCategory from 'shared/components/Inputs/SelectFaqCategory';
import SelectLanguage from 'shared/components/Inputs/SelectLanguage';
import { useNavigate } from 'react-router-dom';
import {useFaqQuestionQuery} from 'services/faq';
import { useSelector } from 'react-redux';
interface IProps {

}

const CreateFaqQuestion: React.FC<IProps>= (props) =>  {
    let navigate = useNavigate();
    const onSuccessSubmit = () => {
        toast('Mesage type created', {
            duration: 2000,
            position: 'top-center',
            icon: '👍🏻',
            iconTheme: {
              primary: '#000',
              secondary: '#f2f2f2',
            },
            ariaProps: {
              role: 'status',
              'aria-live': 'polite',
            },
          });
        setTimeout(() => {
            navigate(-1)
        }, 1000);
    }
    const { language } = useSelector((state:any) => state.LangReducer)
    const {
        mutate: mutateFaqQuestionCreate,
    } = useFaqQuestionQuery.useCreate(onSuccessSubmit);
     
    const  onSubmit = (data:any) => {
        mutateFaqQuestionCreate(data)
    };
    const { register, handleSubmit,setValue, formState: { errors } } = useForm();
    const {ref:titleRef,...title} = register('title', { required: true })
    const {ref:priorityRef,...priority} = register('priority', { required: true })
    React.useEffect(() => {
        register('content')
    })
    React.useEffect(() => {
        setValue('language_id',language)
    }, [language])
    return (
        <Card className='mt-3'>
            <Toaster />
            <CardBody>
                <h4 className="header-title">Create Message Type</h4>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup row>
                        <Label for="title" sm={3}>
                         Title
                        </Label>
                        <Col sm={9}>
                            <Input id="title" type="text"  {...title} innerRef={titleRef} placeholder="Enter title" />
                            {errors.title && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="priority" sm={3}>
                            Priority
                        </Label>
                        <Col sm={9}>
                            <Input id="priority"  type="number"  {...priority} innerRef={priorityRef}   placeholder="Enter priority" />
                              {errors.title && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="category_id" sm={3}>
                            Category
                        </Label>
                        <Col sm={9}>
                            <SelectFaqCategory {...register('category_id',{ required: true })} onChange={(value:any)=>{
                                setValue('category_id',value.value)
                            }}/>
                            {errors.category_id && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="content" sm={3}>
                            Answer
                        </Label>
                        <Col sm={9}>
                        <CKEditor editor={ ClassicEditor } onChange={( event:any, editor:any ) => {
                            const data = editor.getData();
                            setValue('content', data)
                        }
                            } />
                            {errors.content && <span>This field is required</span>} 
                        </Col>
                    </FormGroup>

                    <Button color="primary" type="submit">
                        Submit
                    </Button>
                </Form>
                
            </CardBody>
        </Card>
      
    )
} 

export default CreateFaqQuestion;
