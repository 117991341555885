import * as React from 'react';
import {
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
} from 'reactstrap';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import useTeamMemberQuery from '../../../services/team_members';
import SelectLanguage from '../../../shared/components/Inputs/SelectLanguage';
import { toBase64 } from 'utils/functions';
import { TeamMembersData } from 'shared/models/FormEntity';

interface IProps {

}

const CreateTeamMembers: React.FC<IProps>= () =>  {
    let navigate = useNavigate();
    const onSuccessSubmit = () => {
        toast('Team Member created', {
            duration: 2000,
            position: 'top-center',
            icon: '👍🏻',
          });
        setTimeout(() => {
            navigate(-1)
        }, 1000);
    }
    const {
        mutate: mutateTeamMemberCreate,
        isLoading,
        error
    } = useTeamMemberQuery.useCreate(onSuccessSubmit);
    React.useEffect(() => {
        if (error) {
            toast.error(error.error as string)
        }
      }, [error])
    interface InternalTeamMembersData extends Omit<TeamMembersData, 'image'> {
        image: any,
    }
    const  onSubmit:any = async (data: InternalTeamMembersData) => {
        let form_data : { [key: string]: string; } = {} 
        for (const [key, value] of Object.entries(data)) {
            if (key === 'image' && data[key].length > 0 ) {
                form_data[key] = await toBase64(value[0]) as string
            }
            else{
                form_data[key]  =value
            }
        }
        mutateTeamMemberCreate(form_data)
    };
   
    const { register, handleSubmit, setValue,formState: { errors } } = useForm();
    const {ref:nameRef,...name} = register('name', { required: true })
    const {ref:surnameRef,...surname} = register('surname', { required: true })
    const {ref:positionRef,...position} = register('position', { required: true })
    const {ref:imageRef,...image} = register('image')
    const { ref: priorityRef, ...priority } = register("priority", {
        required: true,
    });


    return (
   
    <Card className='mt-3'>
            <Toaster />
            <CardBody>
                <h4 className="header-title">Create Team members</h4>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup row>
                        <Label for="priority" sm={3}>
                            Priority
                        </Label>
                        <Col sm={5}>
                            <Input
                                id="priority"
                                type="number"
                                {...priority}
                                innerRef={priorityRef}
                                placeholder="Enter priority"
                            />
                            {errors.phone && (
                                <span>This field is required</span>
                            )}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="name" sm={3}>
                        Name
                        </Label>
                        <Col sm={5}>
                        <Input id="name"  type="text"  {...name} innerRef={nameRef}   placeholder="Enter name" />
                              {errors.name && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="surname" sm={3}>
                        Surname
                        </Label>
                        <Col sm={5}>
                        <Input id="surname"  type="text"  {...surname} innerRef={surnameRef}   placeholder="Enter  surname" />
                              {errors.surname && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="surname" sm={3}>
                        Position
                        </Label>
                        <Col sm={5}>
                        <Input id="position"  type="text"  {...position} innerRef={positionRef}   placeholder="Enter position" />
                              {errors.position && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="language_id" sm={3}>
                            Select language
                        </Label>
                        <Col sm={5}>
                            <SelectLanguage {...register('language_id')} onChange={(value:any)=>{
                                setValue('language_id',value.value)
                            }}/>
                        </Col>
                    </FormGroup> 
                    <FormGroup row>
                        <Label for="file" sm={3}>
                            Default file input
                        </Label>
                        <Col sm={5}>
                            <Input className='border-0 pl-0' type="file" id="file"  {...image} innerRef={imageRef}/>
                        </Col>
                    </FormGroup>
                    <Button color="primary" type="submit">
                        {
                            isLoading ? 'Loading...' : 'Create'
                        }
                    </Button>
                </Form>
            </CardBody>
        </Card>
      
    )
} 

export default CreateTeamMembers;
