import * as React from 'react';
import {
    Card,
    CardBody,
    Button,
    Table,
} from 'reactstrap';

import { Link } from 'react-router-dom';
import { useSocialQuery } from '../../services/social';
import * as FeatherIcon from 'react-feather';
import { useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';

interface IProps {

}

const Social: React.FC<IProps>= () =>  {
    const onDeleteSubmit = () => {
      toast('Social has been successfully deleted.', {
          duration: 2000,
          position: 'top-center',
          icon: '🗑',
        });
  }
       const {
        mutate: mutateSocialDelete,
    } = useSocialQuery.useDelete(() => onDeleteSubmit());
    const tableDeleteAction = (id:number) =>{
      
      if (window.confirm("Do you really want to delete this office 😳?") === true) {
        mutateSocialDelete(
            {
                'id':id
            }
        )
      } 
    }
    const { language } = useSelector((state:any) => state.LangReducer);
    const { search_text } = useSelector((state:any) => state.SearchReducer);

    const {
        data: SocialData,
    } = useSocialQuery.useGetAll({
      'language_id':language,
      ...(search_text ? {"search_key": search_text} : "")
    });
    return (
      <>
            <Card className="mt-3">
              <Toaster/>
              <CardBody>
                <Link to="/wadmin/social/create">
                  <Button color="primary" type="submit">
                    Create Social
                  </Button>
                </Link>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Table className="mb-0">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Class name</th>
                            <th>Url</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {SocialData?.map((record, index) => {
                            return (
                                <tr key={index}>
                                    <th scope="row">{record.id}</th>
                                    <td>{record.name}</td>
                                    <td>{record.icon}</td>
                                    <td>{record.url}</td>
                                    <td> <Link to={"/wadmin/social/"+record.unique_identifier+"/edit"}><Button color="outline-info"> <FeatherIcon.Edit className="icon-xs icon-dual"></FeatherIcon.Edit></Button></Link></td>
                                    <td>
                                        <form onSubmit={(event) => {
                                          event?.preventDefault()
                                          tableDeleteAction(record.id as number)
                                        }} className='main-input justify-content-lg-center'>
                                          <Button color="outline-danger" type='submit'> <FeatherIcon.Trash className="icon-xs icon-dual"></FeatherIcon.Trash></Button>
                                        </form>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
                </CardBody>
            </Card>

        {/* {Social.length ? (
          <>
            <Card>
              <CardBody>
              <Table className="mb-0">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Username</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Social.map((record, index) => {
                            return (
                                <tr key={index}>
                                    <th scope="row">{record.id}</th>
                                    <td>{record.firstName}</td>
                                    <td>{record.lastName}</td>
                                    <td>{record.username}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
                <RemotePagination
                  data={Social?.objects || []}
                  page={page + 1}
                  sizePerPage={Social?.meta.limit}
                  totalSize={
                    (Social?.meta.limit as number) *
                    (Social?.meta.max_page as number)
                  }
                  keyField={""}
                  columns={columns}
                  onTableChange={handleTableChange} // onTableChange={ this.handleTableChange }
                /> 
              </CardBody>
            </Card>
          </>
        ) : (
          <div>No content</div>
        )} */}
      </>
    );
} 

export default Social;
