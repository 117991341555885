import * as React from 'react';

import {
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
} from 'reactstrap';
import { useForm } from "react-hook-form";
import toast, { Toaster } from 'react-hot-toast';
import SelectLanguage from '../../../shared/components/Inputs/SelectLanguage';
import { useNavigate } from 'react-router-dom';
import useSocialQuery from '../../../services/social';
interface IProps {

}

const CreateSocial: React.FC<IProps>= () =>  {
    let navigate = useNavigate();
    const onSuccessSubmit = () => {
        toast('Social created', {
            duration: 2000,
            position: 'top-center',
            icon: '👍🏻',
            iconTheme: {
              primary: '#000',
              secondary: '#f2f2f2',
            },
            ariaProps: {
              role: 'status',
              'aria-live': 'polite',
            },
          });
        setTimeout(() => {
            navigate(-1)
        }, 1000);
    }
    const {
        mutate: mutateSocialCreate,
    } = useSocialQuery.useCreate(onSuccessSubmit);
    
   

    const  onSubmit = (data:{}) => {
        mutateSocialCreate(data)
    }
    
    const { register, handleSubmit,setValue, formState: { errors } } = useForm();
    const {ref:priorityRef,...priority} = register('priority', { required: true })
    const {ref:nameRef,...name} = register('name', { required: true })
    const {ref:iconRef,...icon} = register('icon', { required: true })
    const {ref:urlRef,...url} = register('url', { required: true })

    return (
        <Card className='mt-3'>
            <Toaster />
            <CardBody>
                <h4 className="header-title">Create Social</h4>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup row>
                        <Label for="priority" sm={3}>
                         Priority
                        </Label>
                        <Col sm={9}>
                            <Input id="priority" type="number"  {...priority} innerRef={priorityRef} placeholder="Enter priority" />
                            {errors.priority && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="name" sm={3}>
                         Name
                        </Label>
                        <Col sm={9}>
                            <Input id="name" type="text"  {...name} innerRef={nameRef} placeholder="Enter name" />
                            {errors.name && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="icon" sm={3}>
                         Icon
                        </Label>
                        <Col sm={9}>
                            <Input 
                                id="icon"
                                type="text"
                                {...icon}
                                innerRef={iconRef}
                                placeholder="Enter icon"
                            />
                            {errors.icon && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="url" sm={3}>
                         Url
                        </Label>
                        <Col sm={9}>
                            <Input id="url" type="text"  {...url} innerRef={urlRef} placeholder="Enter url" />
                            {errors.url && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="language_id" sm={3}>
                            Language
                        </Label>
                        <Col sm={9}>
                            <SelectLanguage {...register('language_id')} onChange={(value:any)=>{
                                setValue('language_id',value.value)
                            }}/>
                            {errors.language_id && <span>This field is required</span>}
                        </Col>
                    </FormGroup>

                    <Button color="primary" type="submit">
                        Submit
                    </Button>
                </Form>
                
            </CardBody>
        </Card>
      
    )
} 

export default CreateSocial;
