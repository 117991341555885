import { LangAction, LangState, SET_LANGUAGE } from "./types";

const initialState:LangState = {
    language:14
}
const LangReducer =(state = initialState,action:LangAction)=>{
    switch (action.type) {
        case SET_LANGUAGE:
            return{
                ...state,
                language:action.payload
            }
    
        default:
            return state;
    }
}
export default LangReducer