import React, { useState } from 'react'
import {
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Row,
} from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import { useFieldArray, useForm,Controller } from "react-hook-form";
import {
    useNavigate,
    useParams
  } from "react-router-dom";
import usePopularDestinationsQuery from 'services/popular_destination';
import NavTabsItem from 'shared/components/Tabs';
import SelectCountry from 'shared/components/Inputs/SelectCountry';
import SelectLanguage from 'shared/components/Inputs/SelectLanguage';
import useCountryQuery from 'services/countries';
import { PopularDestinationsEditData } from 'shared/models/FormEntity';
import { toBase64 } from 'utils/functions';
import useLanguagesQuery from 'services/languages';
interface IProps {

}

const EditPopularDestinations: React.FC<IProps>= () =>{
  const params = useParams();
  let navigate = useNavigate();

  const { data: PopularDestinations,isLoading } = usePopularDestinationsQuery.useGetSingle(params.id)

  const [activeTab, setActiveTab] = useState('1');
  const { register, control, handleSubmit, setValue, watch, formState: { errors } } = useForm();
  const {ref:imageRef,...image} = register('image')
  const watchDestination = watch('popular_destinations.0.destination_alpha_3')
  // const watchResidency = watch('popular_destinations.0.residency_alpha_3')

  const { fields, append } = useFieldArray({
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "popular_destinations", // unique name for your Field Array
  });
  React.useEffect(() => {
    if (PopularDestinations) {
    PopularDestinations.forEach((tm) => {
        append(tm);
      });
    }
  }, [PopularDestinations]);
  const onSuccessSubmit = () => {
    toast('Popular Destination updated', {
        duration: 2000,
        position: 'top-center',
        icon: '👍🏻',
      });
    setTimeout(() => {
        navigate(-1)
    }, 1000);
}

const {
  mutate: mutatePopularDestinationUpdate,
} = usePopularDestinationsQuery.useUpdate(onSuccessSubmit);

const destination_name = useCountryQuery.useGetSingle(watchDestination);
// const residency = useCountryQuery.useGetSingle(watchResidency);

// let residency_name: string | undefined = undefined;
// React.useEffect(() => {
//   if (residency.data?.[0]) {
//     residency_name = residency.data?.[0].name;
//   }
// }, [residency.data?.[0]])

const {data: languages} = useLanguagesQuery.useGetAll();
const lang_list = languages?.objects?.filter(lang => lang.short_name.toLowerCase() === 'en');


interface InternalPopularDestinationsData extends Omit<PopularDestinationsEditData, 'image'> {
  popular_destinations: [],
  image: any
}

const onSubmit:any = async (data: InternalPopularDestinationsData) => {
  const { popular_destinations, image } = data
  let imageData = ''
  if (image && image.length > 0) { imageData = await toBase64(image[0]) as string; }
  popular_destinations.map((el:any) => {
    delete el['unique_identifier']
    el['destination_name'] = destination_name?.data?.[0]?.display_name
    el['details'] = [{
      'locale': lang_list?.[0]['short_name'],
      'destination_name': destination_name.data?.[0].name,
      'residency_name': null
    }]
    if (image) { el['image'] = imageData }
  })
  mutatePopularDestinationUpdate(popular_destinations)
};

  return (
    <>
    {isLoading ? (
        <div>Loading...</div>
        ) : (
        <Card className="mt-3">
        <Toaster />
        <CardBody>
            <h4 className="header-title">Popular Destinations</h4>
            <Form onSubmit={handleSubmit(onSubmit)}>
            <Nav tabs>
                {fields?.map((i: any, index) => (
                <>
                    <NavItem>
                    <NavLink
                        className={activeTab === `${index + 1}` ? "active" : ""}
                        onClick={function noRefCheck() {
                        setActiveTab(`${index + 1}`);
                        }}
                    >
                        <NavTabsItem item={i}/>
                    </NavLink>
                    </NavItem>
                </>
                ))}
            </Nav>
            <TabContent activeTab={activeTab}>
                {fields?.map((i: any, index) => (
                <TabPane tabId={(index + 1).toString()} key={i.id}>
                    <Row>
                    <Col sm="12">
                        <div>
                        <FormGroup row >
                          <Label for="priority" sm={3}>
                            Priority
                          </Label>
                          <Col sm={9}>
                            <Controller
                              name={`popular_destinations.${index}.priority`}
                              control={control}
                              render={({ field }) => (
                                <Input {...field} type="number" id="priority" />
                              )}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="file" sm={3}>
                              Default file input
                          </Label>
                          <Col sm={5}>
                            <Input type="file" id="file"  {...image} innerRef={imageRef}/>
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label for="destination_alpha_3" sm={3}>
                              Destination
                          </Label>
                          <Col sm={9}>
                            <Controller
                              name={`popular_destinations.${index}.destination_alpha_3`}
                              control={control}
                              render={({ field: { value } }) => (
                                <SelectCountry getValue={value} onChange={(value: any) => {
                                  setValue(`popular_destinations.${index}.destination_alpha_3`, value.value)
                                }}
                                />
                              )}
                            />
                            {errors.destination_alpha_3 && <span>This field is required</span>}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label for="duration" sm={3}>
                              Processing time
                          </Label>
                          <Col sm={9}>
                            <Controller
                              name={`popular_destinations.${index}.duration`}
                              control={control}
                              render={({ field }) => (
                                <Input {...field} type="text" id="duration" />
                              )}
                            />
                            {errors.duration && <span>This field is required</span>}
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="language_id" sm={3}>
                              Language
                          </Label>
                          <Col sm={9}>
                            <Controller
                              name={`popular_destinations.${index}.language_id`}
                              control={control}
                              render={({ field: { value } }) => (
                                <SelectLanguage getValue={value} onChange={(value: any) => {
                                  setValue(`popular_destinations.${index}.language_id`, value.value)
                                }}
                                />
                              )}
                            />
                            {errors.language_id && <span>This field is required</span>}
                          </Col>
                        </FormGroup>                                  
                        </div>
                    </Col>
                    </Row>
                </TabPane>
                ))}
            </TabContent>
            <Button color="primary" type="submit">
                Submit
            </Button>
            </Form>
        </CardBody>
        </Card>
    )}
    </>
  );
}

export default EditPopularDestinations