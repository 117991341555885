import { AxiosError } from 'axios';
import { IError } from '../models';

/**
 * Check whether the object is IError (Typeguard function).
 *
 * @param {any | IError} obj Checked object.
 */
export function isError(obj: any | string | IError): obj is IError {
    if (!Boolean(obj)) {
        return true;
    }
    if (typeof obj === 'string') {
        return obj === 'error';
    }
    return Boolean((obj as IError).message) && Boolean((obj as IError).statusCode);
}

/**
 * Error wrapper.
 *
 * @param error Server-side or browser-side error.
 * @returns Error wrapped into IErrorsResult object.
 */
export async function errorWrapper(error: AxiosError<IError>): Promise<IError> {
    console.log('WHAT IS UNCOMPRESSED ERROR', error);
    // const defaultErrorMessage = translate(/'Error.unknown');
    const defaultErrorMessage = 'Error.unknown';
    let result: IError;

    if (error.response) {
        let data: any = error.response.data;

        result = {
            statusCode: error.response.status || 400,
            message: defaultErrorMessage,
            error: error.response.statusText || 'UNKNOWN_ERROR',
        };

        if (error.response.data) {

            /* tslint:enable */
            if (isError(data)) {
                // server managed error
                result = { ...data, error: error.response.statusText, statusCode: error.response.status };
            }
        }
    } else {
        result = {
            statusCode: error.code === 'ECONNABORTED' ? 408 : 400,
            message: defaultErrorMessage,
            error: error.code || 'UNKNOWN_ERROR',
        };
    }

    return result;
}

