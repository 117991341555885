import React, { useState } from 'react'
import {
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Button,
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Row,
  Input,
} from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import { useFieldArray, useForm,Controller } from "react-hook-form";
import {
    useNavigate  } from "react-router-dom";
import useStaticContentQuery from 'services/static_contents';
import { CKEditor  } from '@ckeditor/ckeditor5-react';
import  ClassicEditor  from '@ckeditor/ckeditor5-build-classic';
import NavTabsItem from 'shared/components/Tabs';
import { StaticContentsData } from 'shared/models/FormEntity';
interface IProps {
    defaultArray:StaticContentsData[] | []
}

const EditStaticContent: React.FC<IProps>= (props) =>{
    const { defaultArray } =props
  let navigate = useNavigate();

  
  const [activeTab, setActiveTab] = useState('1');

  const { control, handleSubmit, watch, setValue } = useForm(
    { defaultValues: {
      name: "default name",
      static_contents: defaultArray
    }
  }
  );
  const { fields } = useFieldArray({
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "static_contents", // unique name for your Field Array
  });
    const watchFieldArray = watch("static_contents");
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index]
        };
    });

  const onSuccessSubmit = () => {
    toast('Static content updated', {
        duration: 2000,
        position: 'top-center',
        icon: '👍🏻',
      });
    setTimeout(() => {
        navigate(-1)
    }, 1000);
}

const {
  mutate: mutateStaticContentUpdate,
} = useStaticContentQuery.useUpdate(onSuccessSubmit);
const onSubmit = (data: any) => {
  const { static_contents } =data
  static_contents.map(
   (el:any)=>
    {
      delete el['unique_identifier']
    }
  )
  mutateStaticContentUpdate(static_contents)
};

  return (
    <>
        <Card className="mt-3">
        <Toaster />
        <CardBody>
            <h4 className="header-title">Static content</h4>
            <Form onSubmit={handleSubmit(onSubmit)}>
            <Nav tabs>
                {controlledFields?.map((i: any, index) => (
                <>
                    <NavItem>
                    <NavLink
                        className={activeTab === `${index + 1}` ? "active" : ""}
                        onClick={function noRefCheck() {
                        setActiveTab(`${index + 1}`);
                        }}
                    >
                        <NavTabsItem item={i}/>
                    </NavLink>
                    </NavItem>
                </>
                ))}
            </Nav>
            <TabContent activeTab={activeTab}>
                {controlledFields?.map((i: any, index) => (
                <TabPane tabId={(index + 1).toString()} key={i.id}>
                    <Row>
                    <Col sm="12">
                        <div>
                        <FormGroup row>
                          <Label for="key" sm={3}>
                            Key
                          </Label>
                          <Col sm={9}>
                            <Controller 
                              name={`static_contents.${index}.key`}
                              control={control}
                              render={({ field }) => (
                                <Input {...field} type="text" id="key"/>
                              )}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="slug" sm={3}>
                            Slug
                          </Label>
                          <Col sm={9}>
                            <Controller 
                              name={`static_contents.${index}.slug`}
                              control={control}
                              render={({ field }) => (
                                <Input {...field} type="text" id="slug"/>
                              )}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="value" sm={3}>
                            Value
                            </Label>
                            <Col sm={9}>
                            {/* {errors.value && <span>This field is required</span>} */}
                            <Controller
                                name={`static_contents.${index}.value`}
                                control={control}
                                render={({ field }) => (
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={i.value}
                                    {...field}
                                    onChange={(event: any, editor: any) => {
                                    const data = editor.getData();
                                    setValue(
                                        `static_contents.${index}.value`,
                                        data
                                    );
                                    }}
                                />
                                // <Input {...field} type="text" id="text" />
                                )}
                            />
                            </Col>
                        </FormGroup>
                        </div>
                    </Col>
                    </Row>
                </TabPane>
                ))}
            </TabContent>
            <Button color="primary" type="submit">
                Submit
            </Button>
            </Form>
        </CardBody>
        </Card>
    </>
  );
}

export default EditStaticContent