import * as React from 'react';
import {
    Card,
    CardBody,
    Button,
} from 'reactstrap';

import { Link, useNavigate } from 'react-router-dom';
import RemotePagination from '../../shared/components/Pagination';
import usePopularDestinationsQuery from '../../services/popular_destination';
import * as FeatherIcon from 'react-feather';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

interface IProps {

}

const PopularDestinations: React.FC<IProps>= (props) =>  {
    const onDeleteSubmit = () => {
      toast('Template variable has been successfully deleted.', {
        duration: 2000,
        position: 'top-center',
        icon: '👍🏻',
      });
    }
    let navigate = useNavigate();
    function UpdatePopularDestinations(cell:any, row:any) {
        return (
           <Button color="outline-info"> <FeatherIcon.Edit className="icon-xs icon-dual"></FeatherIcon.Edit></Button>
        );
      }

    function DeletePopularDestination() {
      return (
        <Button color="outline-danger"> <FeatherIcon.Trash className="icon-xs icon-dual"></FeatherIcon.Trash></Button>
      );
    }
    
    const {
      mutate: mutatePopularDestinationDelete,
    } = usePopularDestinationsQuery.useDelete(() => onDeleteSubmit());

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
        },
        {
          dataField: 'priority',
          text: 'Priority',
        },
        {
          dataField: 'destination_name',
          text: 'Destination',
        },
        {
            dataField: 'duration',
            text: 'Processing time',
        },
        {
            dataField: 'unique_identifier',
            text: 'Edit',
            formatter: UpdatePopularDestinations,
            events: {
                onClick: (e:any, column:any, columnIndex:any, row:any, rowIndex:any) => {
                  navigate(`${row.unique_identifier}/edit`, { replace: false });
                //   alert('Click on Product ID field');
                }
            }
        },
        {
          dataField: 'delete',
          text: 'Delete',
          formatter: DeletePopularDestination,
          events: {
              onClick: (e:any, column:any, columnIndex:any, row:any, rowIndex:any) => {
                  if (window.confirm("Do you really want to delete this template variable 😳?") === true) {
                      mutatePopularDestinationDelete({'id':row.id})
                    } else {
                     
                    }
              }
          }
        }
    ];
    const { language } = useSelector((state:any) => state.LangReducer);
    const { search_text } = useSelector((state:any) => state.SearchReducer);
    
    const [page,setPage] = React.useState(0)
    const {
        data: PopularDestinations,
    } = usePopularDestinationsQuery.useGetAll({
            'language_id':language,
            'limit':10,
            'prev':page * 10,
            ...(search_text ? {"search_key": search_text} : "")
        }
    );
    const  handleTableChange = (type:any,{page,sizePerPage}:any) => {
        setPage(page-1)
    }

    const sortedPopularDestinations = PopularDestinations?.objects
    ? [...PopularDestinations.objects].sort((a, b) => a.priority - b.priority)
    : [];
 
    return (
      <>
            <Card className="mt-3">
              <CardBody>
                <Link to="/wadmin/popular-destinations/create">
                  <Button color="primary" type="submit">
                    Create PopularDestinations
                  </Button>
                </Link>
              </CardBody>
            </Card>
        {PopularDestinations?.objects && PopularDestinations?.objects.length ? (
          <>
            <Card>
              <CardBody>
                <RemotePagination
                  data={sortedPopularDestinations}
                  page={page + 1}
                  sizePerPage={PopularDestinations?.meta.limit}
                  totalSize={
                    (PopularDestinations?.meta.limit as number) *
                    (PopularDestinations?.meta.max_page as number)
                  }
                  keyField={""}
                  columns={columns}
                  onTableChange={handleTableChange} // onTableChange={ this.handleTableChange }
                />
              </CardBody>
            </Card>
          </>
        ) : (
          <div className='d-flex align-items-center justify-content-center flex-column'>
            <h4>No content</h4>
            <img src="https://cdn-icons-png.flaticon.com/512/7303/7303353.png" alt="" width='50px' />
          </div>
        )}
      </>
    );
} 

export default PopularDestinations;
