import * as React from 'react';
import {
    Card,
    CardBody,
    Button,
} from 'reactstrap';

import { useNavigate } from 'react-router-dom';
import RemotePagination from '../../shared/components/Pagination';
import * as FeatherIcon from 'react-feather';
import useUsersQuery from '../../services/users';
import { useSelector } from 'react-redux';

interface IProps {

}

const Users: React.FC<IProps>= (props) =>  {
    let navigate = useNavigate();
    function viewUserDetail(cell:any, row:any) {
        return (
           <Button color="outline-info"> <FeatherIcon.Eye className="icon-xs icon-dual"></FeatherIcon.Eye></Button>
        );
      }
    const columns = [
        {
            dataField: 'name',
            text: 'Name',
        },
        {
            dataField: 'surname',
            text: 'Surname',
        },
        {
            dataField: 'phone',
            text: 'Phone',
        },
        {
            dataField: 'email',
            text: 'Email',
        },
        {
            dataField: 'customer_id',
            text: 'Action',
            formatter: viewUserDetail,
            events: {
                onClick: (e:any, column:any, columnIndex:any, row:any, rowIndex:any) => {
                  navigate(`${row.id}`, { replace: false });
                //   alert('Click on Product ID field');
                }
            }
        }
    ];
   
    const [page,setPage] = React.useState(0);
    const {search_text} = useSelector((state:any) => state.SearchReducer);
    
    const {
        data: Users,
    } = useUsersQuery.useGetAll(
        {
            ...(search_text ? {"search_key": search_text} : "")
        }
    );
    const  handleTableChange = (type:any,{page,sizePerPage}:any) => {
        setPage(page-1)
    }

    return (
        <>
            <Card className='mt-5'>
                <CardBody>
                    <RemotePagination
                        data={Users?.objects || []}
                        page={page+1}
                        sizePerPage={Users?.meta.limit}
                        totalSize={ (Users?.meta.limit as number) * (Users?.meta.max_page as number)} keyField={''} columns={columns}
                        onTableChange={handleTableChange}               // onTableChange={ this.handleTableChange }
                    />

                </CardBody>
            </Card>
        </>
   
      
    )
} 

export default Users;
