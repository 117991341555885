import * as React from "react";
import useLanguagesQuery from "../../../../services/languages";
import { Modify } from "../../../../utils/functions";
import Select from 'react-select';
import { StateManagerProps } from "react-select/dist/declarations/src/stateManager";
import { LangOptionsEntity } from "../../../models/FormEntity";

export interface ISelectLanguageProps extends StateManagerProps {
  getValue?: any
}

const SelectLanguage: React.FunctionComponent<ISelectLanguageProps> = (props) => {
  const {getValue} = props;
  const {
    data: languages,
    isLoading,
  } = useLanguagesQuery.useGetAll();
  const getLangOptions = (languages:LangOptionsEntity[] | undefined) => {
    return languages?.map(lang => {
         return {
           label: lang.long_name,
           value: lang.id
         }
    })
}
  return <Select
        className="basic-single"
        classNamePrefix="select"
        isLoading={isLoading}
        name="color"
        value={getLangOptions(languages?.objects)?.find(c => c.value === getValue)}
        options={Modify.selectOptions(languages?.objects|| [])}
        {...props}
        />
};

export default SelectLanguage;
