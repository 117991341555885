import * as React from 'react';

import {
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
} from 'reactstrap';
import { useForm } from "react-hook-form";
import toast, { Toaster } from 'react-hot-toast';
import SelectLanguage from '../../../shared/components/Inputs/SelectLanguageByName';
import { useNavigate } from 'react-router-dom';
import usePopularDestinationsQuery from '../../../services/popular_destination';
import SelectCountry from '../../../shared/components/Inputs/SelectCountry';
import { PopularDestinationsData, CountriesData } from 'shared/models/FormEntity';
import { toBase64 } from 'utils/functions';
import useCountryQuery from 'services/countries';
interface IProps {

}

const CreatePopularDestinations: React.FC<IProps>= (props) =>  {
    const { register, handleSubmit,control,setValue, watch, formState: { errors } } = useForm();
    const {ref:priorityRef,...priority} = register('priority', { required: true })
    const {ref:durationRef,...duration} = register('duration')
    const {ref:imageRef,...image} = register('image', { required: true })
    const watchDestination = watch('destination_alpha_3')
    // const watchResidency = watch('residency_alpha_3')
    let navigate = useNavigate();
    const onSuccessSubmit = () => {
        toast('Popular Destinations created', {
            duration: 2000,
            position: 'top-center',
            icon: '👍🏻',
          });
        setTimeout(() => {
            navigate(-1)
        }, 1000);
    }
    const {
        mutate: mutatePopularDestinationsCreate,
        isLoading,
        error
    } = usePopularDestinationsQuery.useCreate(onSuccessSubmit);

    const destination_name = useCountryQuery.useGetSingle(watchDestination);
    // const residency = useCountryQuery.useGetSingle(watchResidency);

    // let residency_name: string | undefined = undefined;
    // React.useEffect(() => {
    //     if (residency.data?.[0]) {
    //         residency_name = residency.data?.[0].name;
    //     }
    // }, [residency.data?.[0]])
    

    React.useEffect(() => {
        if (error) {
            toast.error(error.error as string)
        }
    }, [error])
      interface InternalPopularDestinationsData extends Omit<PopularDestinationsData, 'image'> {
        image: any
    }
    const  onSubmit:any = async (data: InternalPopularDestinationsData) => {
        let form_data : { [key: string]: string | [{}]; } = {} 
        for (const [key, value] of Object.entries(data)) {
            if (key === 'image' && data[key].length > 0 ) {
                form_data[key] = await toBase64(value[0]) as string
            }
            else{
                form_data[key]  =value
            }
        }

        form_data["details"] = [{
            'locale': data.language_name,
            'destination_name': destination_name.data?.[0].name,
            'residency_name': null
        }]
        mutatePopularDestinationsCreate(form_data)

    };
    // const  onSubmit = (data:any) => {
    //     var form_data = new FormData();
    //     for ( var key in data ) {
    //         if (key === 'image') {
    //             form_data.append(key, data[key][0])
    //         }
    //         else{
    //             form_data.append(key, data[key])
    //         }
    //     }
    //     mutatePopularDestinationsCreate(form_data)
    // };

    return (
        <Card className='mt-3'>
            <Toaster />
            <CardBody>
                <h4 className="header-title">Create Popular Destinations</h4>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup row>
                        <Label for="priority" sm={3}>
                         Priority
                        </Label>
                        <Col sm={9}>
                            <Input id="priority" type="number"  {...priority} innerRef={priorityRef} placeholder="Enter priority" />
                            {errors.priority && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="file" sm={3}>
                            Default file input
                        </Label>
                        <Col sm={5}>
                            <Input type="file" id="file"  {...image} innerRef={imageRef}/>
                            {errors.image && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="destination_alpha_3" sm={3}>
                            Destination
                        </Label>
                        <Col sm={9}>
                            <SelectCountry {...register('destination_alpha_3')} onChange={(value:any)=>{
                                setValue('destination_alpha_3',value.value)
                            }}/>
                            {errors.destination_alpha_3 && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="duration" sm={3}>
                            Processing time
                        </Label>
                        <Col sm={9}>
                            <Input id="duration" type="text"  {...duration} innerRef={durationRef} placeholder="Enter processing time" />
                            {errors.duration && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="language_name" sm={3}>
                            Language
                        </Label>
                        <Col sm={9}>
                            <SelectLanguage {...register('language_name')} onChange={(value:any)=>{
                                setValue('language_name',value.value)
                            }}/>
                            {errors.language_name && <span>This field is required</span>}
                        </Col>
                    </FormGroup>

                    <Button color="primary" type="submit">
                        {
                            isLoading ? 'Loading...' : 'Create'
                        }
                    </Button>
                </Form>
                
            </CardBody>
        </Card>
      
    )
} 

export default CreatePopularDestinations;
