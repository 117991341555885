import * as React from 'react';
import {
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
} from 'reactstrap';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import  { useLanguagesQuery } from 'services/languages';

interface IProps {

}
const CreateLanguage: React.FC<IProps>= (props) =>  {
    let navigate = useNavigate();
    const onSuccessSubmit = () => {
        toast('Language created', {
            duration: 2000,
            position: 'top-center',
            icon: '👍🏻',
          });
        setTimeout(() => {
            navigate(-1)
        }, 1000);
    }
    const {
        mutate: mutateLanguageCreate,
        isLoading,
        error
    } = useLanguagesQuery.useCreate(onSuccessSubmit);
    React.useEffect(() => {
        if (error) {
            toast.error(error.error as string)
        }
    }, [error])
    const  onSubmit = (data:{}) => {
        mutateLanguageCreate(data)
    };
  
    const { register, handleSubmit, formState: { errors } } = useForm();
    const {ref:short_nameRef,...short_name} = register('short_name', { required: true })
    const {ref:long_nameRef,...long_name} = register('long_name', { required: true })
    const {ref:is_activeRef,...is_active} = register('is_active')


    return (
   
    <Card className='mt-3'>
            <Toaster />
            <CardBody>
                <h4 className="header-title">Create Language</h4>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup row>
                        <Label for="short_name" sm={3}>
                         Short name
                        </Label>
                        <Col sm={5}>
                        <Input id="short_name"  type="text"  {...short_name} innerRef={short_nameRef}   placeholder="Enter short name" />
                              {errors.short_name && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="long_name" sm={3}>
                         Long name
                        </Label>
                        <Col sm={5}>
                        <Input id="long_name"  type="text"  {...long_name} innerRef={long_nameRef}   placeholder="Enter long name" />
                              {errors.long_name && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                    <Col sm={5}>
                        <FormGroup
                                check
                                inline
                                row
                            >
                                <Input type="checkbox" id="is_active"   {...is_active} innerRef={is_activeRef} />
                                <Label check  for='is_active'>
                               is active
                                </Label>
                        </FormGroup>
                      </Col>
                   <Col sm={12} className='mt-3'>
                    <Button color="primary" type="submit">
                        {
                            isLoading ? 'Loading...' : 'Create'
                        }
                    </Button>
                   </Col>
                </Form>
            </CardBody>
        </Card>
      
    )
} 

export default CreateLanguage;
