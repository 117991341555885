import { AxiosResponse } from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { StaticContents, StaticContentsData } from "../../shared/models/FormEntity";
import { APIClient } from "../../utils/api/client";
import { IError } from "../../utils/models";
import { getBaseUrl } from "../endpoints";

import { queryToString } from "../../utils/functions";

export interface IGetStaticContentsConfig {
}


const StaticContentServices = {
  getAll: (data?: any): Promise<StaticContents> => {
    return APIClient.get(getBaseUrl({path:`content/static_content${queryToString(data)}`}));
  },
  getSingle: (data: string | string[] | undefined): Promise<StaticContentsData[]> => {
    return APIClient.get(getBaseUrl({path:`content/static_content/${data}`}));
  },
  create: (data?: any): Promise<AxiosResponse<StaticContents>> => {
    return APIClient.post( getBaseUrl({path:'content/static_content'}), data);
  },
  update: (data?: any): Promise<AxiosResponse<StaticContentsData[]>> => {
    return APIClient.put( getBaseUrl({path:'content/static_content'}), data);
  },
  delete: (data?: any): Promise<AxiosResponse<{}>> => {
    return APIClient.delete(getBaseUrl({path:'content/static_content'}),{ data:data });
  },
};

export const useStaticContentQuery = {
  useGetAll: (
    data?: any,
    meta?: IGetStaticContentsConfig
  ): UseQueryResult<StaticContents, IError> => {
    return useQuery<StaticContents, IError>(
      ["get-static-contents", data],
      async (): Promise<StaticContents> => {
        const res = await StaticContentServices.getAll(data);
        return res;
      },
    );
  },
  useGetSingle: (
      identifier: string | string[] | undefined,
      meta?: IGetStaticContentsConfig,
    ): UseQueryResult<StaticContentsData[]> => {
      return useQuery<StaticContentsData[]>(
        ["get-static-content", identifier],
        async (): Promise<StaticContentsData[]> => {
          const res = await StaticContentServices.getSingle(identifier);
          return res;
        },{
          cacheTime:0
        }
      );
    },
  useCreate: (onSuccess:() => void): UseMutationResult<StaticContents, IError> => {
    return useMutation<StaticContents, IError, unknown>(
      async (data:any): Promise<StaticContents> => {
        const res = await StaticContentServices.create(data);
        return res?.data;
      },
      {
        onSuccess:onSuccess
      }
    );
  },
  useUpdate: (onSuccess:() => void): UseMutationResult<StaticContentsData[], IError> => {
    return useMutation<StaticContentsData[], IError, unknown>(
      async (data:any): Promise<StaticContentsData[]> => {
        const res = await StaticContentServices.update(data);
        return res?.data;
      },
      {
        onSuccess:onSuccess
      }
    );
  },
  useDelete: (onSuccess:(data:any) => void): UseMutationResult<{}, IError> => {
    return useMutation<{}, IError, unknown>(
      async (data:any): Promise<{}> => {
        const res = await StaticContentServices.delete(data);
        return res;
      },
      {
        onSuccess:onSuccess
      },
      
    );
  },
};

export default useStaticContentQuery;
