import * as React from 'react';


import {
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
} from 'reactstrap';
import { useForm } from "react-hook-form";
import toast, { Toaster } from 'react-hot-toast';
import SelectLanguage from '../../../shared/components/Inputs/SelectLanguage';
import { useNavigate } from 'react-router-dom';
import useOfficesQuery from '../../../services/offices';
interface IProps {

}

const CreateOffice: React.FC<IProps>= () =>  {
    let navigate = useNavigate();
    const onSuccessSubmit = () => {
        toast('Office created', {
            duration: 2000,
            position: 'top-center',
            icon: '👍🏻',
            iconTheme: {
              primary: '#000',
              secondary: '#f2f2f2',
            },
            ariaProps: {
              role: 'status',
              'aria-live': 'polite',
            },
          });
        setTimeout(() => {
            navigate(-1)
        }, 1000);
    }
    const {
        mutate: mutateOfficesCreate,
    } = useOfficesQuery.useCreate(onSuccessSubmit);
     
    const  onSubmit = (data:{}) => {
        mutateOfficesCreate(data)
    };
    const { register, handleSubmit,setValue, formState: { errors } } = useForm();
    const {ref:nameRef,...name} = register('name', { required: true })
    const {ref:addressRef,...address} = register('address', { required: true })
    const {ref:phoneRef,...phone} = register('phone', { required: true })
    const {ref:longitudeRef,...longitude} = register('longitude', { required: true })
    const {ref:latitudeRef,...latitude} = register('latitude', { required: true })
    const {ref:emailRef,...email} = register('email', { required: true })
    const {ref:priorityRef,...priority} = register('priority', { required: true })
    const {ref:office_hoursRef,...office_hours} = register('office_hours', { required: true })
    const {ref:consultation_hoursRef,...consultation_hours} = register('consultation_hours', { required: true })
    const { ref: country_codeRef, ...country_code } = register("country_code", {
        required: true,
    });


    return (
        <Card className='mt-3'>
            <Toaster />
            <CardBody>
                <h4 className="header-title">Create Office</h4>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup row>
                        <Label for="priority" sm={3}>
                        Priority
                        </Label>
                        <Col sm={9}>
                            <Input id="priority" type="number"  {...priority} innerRef={priorityRef} placeholder="Enter priority" />
                            {errors.phone && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="name" sm={3}>
                        Name
                        </Label>
                        <Col sm={9}>
                            <Input id="name" type="text"  {...name} innerRef={nameRef} placeholder="Enter name" />
                            {errors.name && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="address" sm={3}>
                        Address
                        </Label>
                        <Col sm={9}>
                            <Input id="address" type="text"  {...address} innerRef={addressRef} placeholder="Enter address" />
                            {errors.address && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="phone" sm={3}>
                         Phone
                        </Label>
                        <Col sm={9}>
                            <Input id="phone" type="text"  {...phone} innerRef={phoneRef} placeholder="Enter phone" />
                            {errors.phone && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="longitude" sm={3}>
                          Longitude
                        </Label>
                        <Col sm={9}>
                            <Input id="longitude" type="text"  {...longitude} innerRef={longitudeRef} placeholder="Enter longitude" />
                            {errors.longitude && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="latitude" sm={3}>
                        Latitude
                        </Label>
                        <Col sm={9}>
                            <Input id="latitude" type="text"  {...latitude} innerRef={latitudeRef} placeholder="Enter latitude" />
                            {errors.latitude && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                   
                    <FormGroup row>
                        <Label for="email" sm={3}>
                          Email
                        </Label>
                        <Col sm={9}>
                            <Input id="email" type="text"  {...email} innerRef={emailRef} placeholder="Enter email" />
                            {errors.email && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="office_hours" sm={3}>
                          Office hours
                        </Label>
                        <Col sm={9}>
                            <Input id="office_hours" type="text"  {...office_hours} innerRef={office_hoursRef} placeholder="Enter office hours" />
                            {errors.office_hours && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="consultation_hours" sm={3}>
                        Consultation hours
                        </Label>
                        <Col sm={9}>
                            <Input id="consultation_hours" type="text"  {...consultation_hours} innerRef={consultation_hoursRef} placeholder="Enter consultation hours" />
                            {errors.consultation_hours && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="language_id" sm={3}>
                            Language
                        </Label>
                        <Col sm={9}>
                            <SelectLanguage {...register('language_id')} onChange={(value:any)=>{
                                setValue('language_id',value.value)
                            }}/>
                            {errors.language_id && <span>This field is required</span>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="country_code" sm={3}>
                            Country code
                        </Label>
                        <Col sm={9}>
                            <Input
                                id="country_code"
                                type="text"
                                {...country_code}
                                innerRef={country_codeRef}
                                placeholder="Enter country code"
                            />
                            {errors.country_code && (
                                <span>This field is required</span>
                            )}
                        </Col>
                    </FormGroup>

                    <Button color="primary" type="submit">
                        Submit
                    </Button>
                </Form>
                
            </CardBody>
        </Card>
      
    )
} 

export default CreateOffice;
