import * as React from 'react';
import {
    Card,
    CardBody,
    Button,
} from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import RemotePagination from 'shared/components/Pagination';
import {useFaqCategoriesQuery} from 'services/faq';
import * as FeatherIcon from 'react-feather';
import moment from 'moment';
import { useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
interface IProps {

}

const Categories: React.FC<IProps>= (props) =>  {
    let navigate = useNavigate();
    const onDeleteSubmit = () => {
      toast('Faq categories has been successfully deleted.', {
          duration: 2000,
          position: 'top-center',
          icon: '👍🏻',
        });
  }
    function ViewCategories(cell:any, row:any) {
        return (
           <Button color="outline-info"> <FeatherIcon.Edit className="icon-xs icon-dual"></FeatherIcon.Edit></Button>
        );
      }
      function CreatedAt(row:any) {
        return (
            <span>{moment(row.created_at).format("D-MMM-YYYY, h:mm a")}</span>
        );
      }
      function UpdatedAt(row:any) {
        return (
            <span>{moment(row.updated_at).format("D-MMM-YYYY, h:mm a")}</span>
        );
      }
      function DeleteCategory() {
        return (
           <Button color="outline-danger"> <FeatherIcon.Trash className="icon-xs icon-dual"></FeatherIcon.Trash></Button>
        );
    }
    const {
      mutate: mutateStaticDelete,
  } = useFaqCategoriesQuery.useDelete(() => onDeleteSubmit());
    const columns = [
       
        {
            dataField: 'id',
            text: 'Id',
        },
        {
            dataField: 'title',
            text: 'Title',
        },
        {
          dataField: 'priority',
          text: 'Priority',
        },
        {
            dataField: 'created_at',
            text: 'Created at',
            formatter: CreatedAt
        },
        {
            dataField: 'updated_at',
            text: 'Updated at',
            formatter: UpdatedAt
        },
        {
            dataField: 'edit',
            text: 'Edit',
            formatter: ViewCategories,
            events: {
                onClick: (e:any, column:any, columnIndex:any, row:any, rowIndex:any) => {
                  navigate(`${row.unique_identifier}/edit`, { replace: false });
                }
            }
        },
        {
          dataField: 'delete',
          text: 'Delete',
          formatter: DeleteCategory,
          events: {
              onClick: (e:any, column:any, columnIndex:any, row:any, rowIndex:any) => {
                  if (window.confirm("Do you really want to delete this category 😳?") === true) {
                    mutateStaticDelete(
                          {
                              'id':row.id
                          }
                      )
                    refetchCategories()
                    } else {
                     
                    }
              }
          }
      }

    ];
    const { language } = useSelector((state:any) => state.LangReducer);
    const { search_text } = useSelector((state:any) => state.SearchReducer);
   
    const [page,setPage] = React.useState(0)
    const {
        data: Categories,
        refetch: refetchCategories,
    } = useFaqCategoriesQuery.useGetAll({
        query:{
            'language_id':language,
            'limit':10,
            'prev':page * 10,
            ...(search_text ? {"search_key": search_text} : "")
        }
    });
    const  handleTableChange = (type:any,{page,sizePerPage}:any) => {
        setPage(page-1)
    }

    const sortedCategories = Categories?.objects
    ? [...Categories.objects].sort((a, b) => a.priority - b.priority)
    : [];

    return (
        <>
       <CardBody>
                <Link to="/wadmin/faq-categories/create">
                  <Button color="primary" type="submit">
                    Create Category
                  </Button>
                </Link>
          </CardBody>
    {Categories?.objects && Categories?.objects.length ? (
      <>
        <Card>
          <Toaster></Toaster>
          <CardBody>
            <RemotePagination
              data={sortedCategories}
              page={page + 1}
              sizePerPage={Categories?.meta.limit}
              totalSize={
                (Categories?.meta.limit as number) *
                (Categories?.meta.max_page as number)
              }
              keyField={""}
              columns={columns}
              onTableChange={handleTableChange} // onTableChange={ this.handleTableChange }
            />
          </CardBody>
        </Card>
      </>
    ) : (
      <div className='d-flex align-items-center justify-content-center flex-column'>
        <h4>No content</h4>
        <img src="https://cdn-icons-png.flaticon.com/512/7303/7303353.png" alt="" width='50px' />
      </div>
    )}
  </>
      
    )
} 

export default Categories;
