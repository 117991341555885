import * as React from 'react';
import {
    Card,
    CardBody,
    Button,
} from 'reactstrap';
import {  useNavigate } from 'react-router-dom';
import RemotePagination from 'shared/components/Pagination';
import {useMessagesQuery} from 'services/contact';
import * as FeatherIcon from 'react-feather';
import moment from 'moment';
import { useSelector } from 'react-redux';
interface IProps {

}

const Messages: React.FC<IProps>= (props) =>  {
    let navigate = useNavigate();
    function ViewMessages(cell:any, row:any) {
        return (
           <Button color="outline-info"> <FeatherIcon.Edit className="icon-xs icon-dual"></FeatherIcon.Edit></Button>
        );
      }
      function CreatedAt(row:any) {
        return (
            <span>{moment(row).format("D-MMM-YYYY, h:mm a")}</span>
        );
      }
      function UpdatedAt(row:any) {
        return (
            <span>{moment(row).format("D-MMM-YYYY, h:mm a")}</span>
        );
      }
    const columns = [
       
        {
            dataField: 'type.name',
            text: 'Type',
        },
        {
            dataField: 'email',
            text: 'Email',
        },
        {
            dataField: 'created_at',
            text: 'Created at',
            formatter: CreatedAt
        },
        {
            dataField: 'updated_at',
            text: 'Updated at',
            formatter: UpdatedAt
        },
        {
            dataField: 'id',
            text: 'Edit',
            formatter: ViewMessages,
            events: {
                onClick: (e:any, column:any, columnIndex:any, row:any, rowIndex:any) => {
                  navigate(`${row.id}`, { replace: false });
                }
            }
        }
    ];
    const { language } = useSelector((state:any) => state.LangReducer)
   
    const [page,setPage] = React.useState(0)
    const {
        data: Messages,
    } = useMessagesQuery.useGetAll({
        query:{
            'language_id':language,
            'limit':10,
            'prev':page * 10
        }
    });
    const  handleTableChange = (type:any,{page,sizePerPage}:any) => {
        setPage(page-1)
    }

    return (
        <>
       
    {Messages?.objects && Messages?.objects.length ? (
      <>
        <Card>
          <CardBody>
            <RemotePagination
              data={Messages?.objects || []}
              page={page + 1}
              sizePerPage={Messages?.meta.limit}
              totalSize={
                (Messages?.meta.limit as number) *
                (Messages?.meta.max_page as number)
              }
              keyField={""}
              columns={columns}
              onTableChange={handleTableChange} // onTableChange={ this.handleTableChange }
            />
          </CardBody>
        </Card>
      </>
    ) : (
      <div className='d-flex align-items-center justify-content-center flex-column'>
        <h4>No content</h4>
        <img src="https://cdn-icons-png.flaticon.com/512/7303/7303353.png" alt="" width='50px' />
      </div>
    )}
  </>
      
    )
} 

export default Messages;
