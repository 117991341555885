import './App.css';
import './assets/scss/theme.scss';
import IRoutes from './routes/IRoutes';


function App() {
  return (
    <IRoutes></IRoutes>
    
  );
}

export default App;
