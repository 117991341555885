import React, { useState } from "react";
import {
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import toast, { Toaster } from "react-hot-toast";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import useTeamMemberQuery from "../../../services/team_members";
import { useNavigate, useParams } from "react-router-dom";
import NavTabsItem from "shared/components/Tabs";
import { toBase64 } from 'utils/functions';

interface IProps {}

const EditTeamMembers: React.FC<IProps> = () => {
  const params = useParams();
  let navigate = useNavigate();

  const { data: TeamMembers } = useTeamMemberQuery.useGetSingle(params.id);

  const [activeTab, setActiveTab] = useState("1");
  const [imageData, setImageData] = useState<any[]>([])
  const { control, handleSubmit } = useForm();
  const { fields, append } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "team_members", // unique name for your Field Array
  });
  React.useEffect(() => {
    if (TeamMembers) {
      TeamMembers.forEach((tm) => {
        append(tm);
      });
    }
  }, [TeamMembers]);
  const onSuccessSubmit = () => {
    toast("Team Member updated", {
      duration: 2000,
      position: "top-center",
      icon: "👍🏻",
    });
    setTimeout(() => {
      navigate(-1)
    }, 1000);
  };

  const handleImageSelect = async (files: any, index: number) => {
    let imagesArray: any[] = [...imageData] 
    imagesArray[index] = await toBase64(files[0]) as string
    setImageData(imagesArray)
  }

  const { mutate: mutateTeamMember, error } =
    useTeamMemberQuery.useUpdate(onSuccessSubmit);
  React.useEffect(() => {
    if (error) {
      toast.error(error.error as string);
    }
  }, [error]);

  const onSubmit = (data: any) => {
    const { team_members } = data;
    team_members.map((el: any, index: number) => {
      delete el["unique_identifier"];
      if (imageData[index]) {
        el["image"] = imageData[index];
      } else {
        delete el["image"];
      }
    });    
    mutateTeamMember(team_members);
  };
  return (
    <Card className="mt-3">
      <Toaster />
      <CardBody>
        <h4 className="header-title">Team members</h4>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Nav tabs>
            {fields?.map((i: any, index) => (
              <div key={index}>
                <NavItem>
                  <NavLink
                    className={activeTab === `${index + 1}` ? "active" : ""}
                    onClick={function noRefCheck() {
                      setActiveTab(`${index + 1}`);
                    }}
                  >
                    <NavTabsItem item={i} />
                  </NavLink>
                </NavItem>
              </div>
            ))}
          </Nav>
          <TabContent activeTab={activeTab}>
            {fields?.map((i: any, index) => (
              <TabPane tabId={(index + 1).toString()} key={i.id}>
                <Row>
                  <Col sm="12">
                    <div>
                      <FormGroup row>
                        <Label for="name" sm={3}>
                          Priority
                        </Label>
                        <Col sm={9}>
                          <Controller
                            name={`team_members.${index}.priority`}
                            control={control}
                            render={({ field }) => (
                              <Input {...field} type="number" id="text" />
                            )}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="name" sm={3}>
                          Name
                        </Label>
                        <Col sm={9}>
                          <Controller
                            name={`team_members.${index}.name`}
                            control={control}
                            render={({ field }) => (
                              <Input {...field} type="text" id="text" />
                            )}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="surname" sm={3}>
                          Surname
                        </Label>

                        <Col sm={9}>
                          <Controller
                            name={`team_members.${index}.surname`}
                            control={control}
                            render={({ field }) => (
                              <Input {...field} type="text" id="text" />
                            )}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="position" sm={3}>
                          Position
                        </Label>

                        <Col sm={9}>
                          <Controller
                            name={`team_members.${index}.position`}
                            control={control}
                            render={({ field }) => (
                              <Input {...field} type="text" id="text" />
                            )}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="image" sm={3}>
                          Image
                        </Label>
                        <Col sm={9}>
                          <img
                            src={i.image}
                            className="avatar rounded mr-3 undefined mb-3"
                            alt="avatar"
                          />
                          <Input type="file" id="image" onChange={(e) => {
                            handleImageSelect(e.target.files, index)
                          }} />
                        </Col>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
              </TabPane>
            ))}
          </TabContent>
          <Button color="primary" type="submit">
            Submit
          </Button>
        </Form>
      </CardBody>
    </Card>
  );
};

export default EditTeamMembers;
