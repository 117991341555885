import React, { useState } from 'react'
import {
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Row,
} from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import { useFieldArray, useForm,Controller } from "react-hook-form";
import {
    useNavigate,
    useParams
  } from "react-router-dom";
import {useFaqCategoriesQuery, useFaqQuestionQuery} from 'services/faq';
import { CKEditor  } from '@ckeditor/ckeditor5-react';
import  ClassicEditor  from '@ckeditor/ckeditor5-build-classic';
import NavTabsItem from 'shared/components/Tabs';
import Select from 'react-select';
import SelectFaqCategory from 'shared/components/Inputs/SelectFaqCategory';
import { Modify } from 'utils/functions';
import { FaqCategoryData } from "shared/models/FormEntity";
interface IProps {

}

const EditFaqQuestion: React.FC<IProps>= () =>{
  const params = useParams();
  let navigate = useNavigate();

  const { data: FaqQuestion,isLoading } = useFaqQuestionQuery.useGetSingle(params.id)

  const [activeTab, setActiveTab] = useState('1');
  const { control, handleSubmit, setValue } = useForm();
  const { fields, append } = useFieldArray({
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "faq_questions", // unique name for your Field Array
  });
  React.useEffect(() => {
    if (FaqQuestion) {
    FaqQuestion.forEach((tm) => {
        append(tm);
      });
    }
  }, [FaqQuestion]);
  const onSuccessSubmit = () => {
    toast('FaqQuestion updated', {
        duration: 2000,
        position: 'top-center',
        icon: '👍🏻',
      });
    setTimeout(() => {
        navigate(-1)
    }, 1000);
}

const getCategoryData = (language: Number) => {
  return useFaqCategoriesQuery.useGetAll({query: {'language_id': language}}).data
}

const getCategoryOptions = (language: Number) => {
  return Modify.selectOptionsFaqCategory(getCategoryData(language)?.objects || [])
} 
  
const {
  mutate: mutateFaqQuestionUpdate,
} = useFaqQuestionQuery.useUpdate(onSuccessSubmit);

const getCategoriesOptions = (categories:FaqCategoryData[] | undefined) => {
  return categories?.map(category => {
          return {
             label: category.title,
             value: category.id
          }
      })
  }

const onSubmit = (data: any) => {
  const { faq_questions } =data
  faq_questions.map(
   (el:any)=>
    {
      delete el['unique_identifier']
    }
  )
  mutateFaqQuestionUpdate(faq_questions)
};

  return (
    <>
    {isLoading ? (
        <div>Loading...</div>
        ) : (
        <Card className="mt-3">
        <Toaster />
        <CardBody>
            <h4 className="header-title">FaqQuestion</h4>
            <Form onSubmit={handleSubmit(onSubmit)}>
            <Nav tabs>
                {fields?.map((i: any, index) => (
                <div key={index}>
                    <NavItem>
                    <NavLink
                        className={activeTab === `${index + 1}` ? "active" : ""}
                        onClick={function noRefCheck() {
                        setActiveTab(`${index + 1}`);
                        }}
                    >
                        <NavTabsItem item={i}/>
                    </NavLink>
                    </NavItem>
                </div>
                ))}
            </Nav>
            <TabContent activeTab={activeTab}>
                {fields?.map((i: any, index) => (
                <TabPane tabId={(index + 1).toString()} key={i.id}>
                    <Row>
                    <Col sm="12">
                        <div>
                          <FormGroup row >
                            <Label for="title" sm={3}>
                              Title
                            </Label>
                            <Col sm={9}>
                              <Controller
                                name={`faq_questions.${index}.title`}
                                control={control}
                                render={({ field }) => (
                                  <Input {...field} type="text" id="title" />
                                )}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="priority" sm={3}>
                              Priority
                            </Label>
                            <Col sm={9}>
                              <Controller
                                name={`faq_questions.${index}.priority`}
                                control={control}
                                render={({ field }) => (
                                <Input {...field} type="number" id="priority" />
                                )}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for='category_id' sm={3}>
                              Category
                            </Label>
                            <Col sm={9}>
                            <Controller
                              name={`faq_questions.${index}.category_id`} 
                              control={control}
                              render={({field}) => (
                                <Select 
                                  {...field}
                                  name={`faq_questions.${index}.category_id`} 
                                  options={getCategoryOptions(i.language_id)}
                                  id='category_id'
                                  value={getCategoryOptions(i.language_id).find((o) => o.value === field.value)}
                                  onChange={val => field.onChange(val?.value)}
                                />
                              )}
                            />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="content" sm={3}>
                              Content
                            </Label>
                            <Col sm={9}>
                              <Controller
                                name={`faq_questions.${index}.content`}
                                control={control}
                                render={({ field }) => (
                                  <CKEditor
                                    editor={ClassicEditor}
                                    data={i.content}
                                    {...field}
                                    onChange={(event: any, editor: any) => {
                                      const data = editor.getData();
                                      setValue(
                                        `faq_questions.${index}.content`,
                                        data
                                      );
                                    }}
                                  />
                                )}
                              />
                            </Col>
                          </FormGroup>
                        </div>
                     
                    </Col>
                    </Row>
                </TabPane>
                ))}
            </TabContent>
            <Button color="primary" type="submit">
                Submit
            </Button>
            </Form>
        </CardBody>
        </Card>
    )}
    </>
  );
}

export default EditFaqQuestion